/* global fetch */ // nextjs polyfills
// Helper Lib
// cSpell:ignore ngql, carg, Rmllb, GREZW, Zpbml, CFDK, DDTHH, Reallylongname, Withmany, Wordsthat, Cango
import util from 'util'
import slugify from 'slugify'
import packageFile from '../../package.json'
import { cloneDeep as deepCopy, cloneDeep, invert } from 'lodash'
import { timeZones, ignoreDST, states, phoneAreaCodes } from '../dictionaries/dictionaries'
import { uuid as uuidV4 } from 'uuidv4'
const { version: appVersion } = packageFile
export { deepCopy, cloneDeep, invert }// { cloneDeep as deepCopy, cloneDeep, invert } from 'lodash'
export const jsonwebtoken = require('jsonwebtoken')
export const uuid = uuidV4
export const uuidv4 = uuidV4
const hasOwnProperty = Object.prototype.hasOwnProperty
export const moment = require('moment')
export const _ = require('lodash')
export const immutableObjUpdate = require('immutability-helper')
export const safeAwait = require('safe-await')
const mongoose = require('mongoose')
const { ObjectId } = mongoose.Types
ObjectId.prototype.valueOf = function () {
  return this.toString()
}

export const isNumber = (value) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
  if (value === 0) return true
  if (!value) return false
  if (value === ' ') return false
  return !(Number.isNaN(Number(value)))
}

export const replaceStringArrayElement = (searchArray, find, replace) => {
  // consoleDev(thisFile, 'replaceStringArrayElement searchArray:', searchArray, ' \nfind:', find, ' \nreplace:', replace)
  if (searchArray.indexOf(find) > -1) searchArray[searchArray.indexOf(find)] = replace
}
export const columnLetters = ['0', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN']
export const clientWindow = (typeof window !== 'undefined') ? window : undefined
const JsonDiffPatch = require('jsondiffpatch')
// instance of JsonDiffPatch
export const jsonDiffPatch = JsonDiffPatch.create({
  objectHash: function (obj, index) {
    if (obj !== undefined) {
      return (
        (obj._id && obj._id.toString()) ||
        obj.id ||
        obj.key ||
        '$$index:' + index
      )
    }
    return '$$index:' + index
  },
  arrays: {
    detectMove: true
  }
})

const thisFile = 'src/helpers/helper ' // eslint-disable-line no-unused-vars

const FAKE_NODE_ENV_PRODUCTION = false

/**
 *  Is used to get the slug from displayId.  Removes STA- if exists and returns up to the first '-' (hyphen).
 *
 * @param {*} displayId
 * @returns
 */
export const getSlugFromDisplayId = (displayId) => {
  if (displayId.substr(0, 4) === 'STA-') displayId = displayId.substr(4)
  const firstHyphen = displayId.indexOf('-')
  if (~firstHyphen) {
    return displayId.substr(0, firstHyphen)
  } else {
    throw new Error(consoleError(thisFile, 'getSlugFromDisplayId:', displayId, 'failed with firstHyphen:', firstHyphen))
  }
}

/**
 *
 *  Makes stringified messages look nice by removing escape markup
 *
 * @param {*} args
 * @param {*} data
 * @returns
 */
export const stringifyReturnMessage = (args, data) => {
  return jsonStr(args).replace(/"/g, '').replace(/\n/g, '') + ' data: ' +
  jsonStr(data).replace(/"/g, '').replace(/\n/g, '').replace(/\s\s+/g, '') // last one multi spaces
}

/**
 *  Mongoose doesn't like to be called with await and 6.0 enforced.  This makes it valid syntax
 *  to only get called once.
 *
 * @param {*} model
 * @param {*} { search, update, options }
 * @returns
 */
export const findOneAndUpdatePromise = (model, { search, update, options }) => {
  return new Promise((resolve, reject) => {
    model.findOneAndUpdate(search, update, options, (err, doc) => {
      if (err) {
        consoleWarn(`Could not update ${model}. Details: ` + JSON.stringify(err))
        reject(new Error(`Could not update ${model}`))
      }
      resolve(doc)
    })
  })
}

/**
 * @typedef {Object} ConvertedQuestions
 * @property {[Object]} convertedQuestions The given questions with select answerOptions converted.
 * @property {[Object]} convertOperations An array of operations that can be given to MongoDB
 * to bulk convert the answerOptions field.
 */

/**
 * Turn any string delimited answers of a question into an array. If the answers to a
 * question are already an array, this is a no-op. This is a pure function, so it will
 * return operations for the database to do but will not operate on the database itself.
 * @param {[QuestionSchema]} questions
 * @returns {ConvertedQuestions} A ConvertedQuestions object with the changes.
 */
export function convertAnswerOptions (questions) {
  const answerType = 'Select '
  const delimiter = ';'

  return {
    // Map the entire questions array to a new array with any answerOptions converted.
    convertedQuestions: questions.map(question => {
      // Any questions we aren't going to touch should at least be empty arrays, not null.
      if (!question.answerOptions) question.answerOptions = []

      // We really only want to mess with certain answer types.
      return (question.answerType?.includes(answerType))
        ? {

          // Get all the current keys of the question.
          ...question,

          // For answerOptions, if it's a string, split it into an array.
          answerOptions: typeof question.answerOptions === 'string'
            ? question.answerOptions.split(delimiter)

            // If not, return it as-is, or if null an empty array.
            : question.answerOptions || []
        }

        // The answer type didn't match so just return the question as-is.
        : question
    }),

    // Any questions that require answerOptions to be converted, create an updateOne for it.
    convertOperations: questions
      .filter(question => typeof question.answerOptions === 'string')
      .map(question => {
        return {
          updateOne: {
            filter: { _id: new ObjectId(question.id) },
            update: { $set: { answerOptions: question.answerOptions.split(delimiter) } }
          }
        }
      })
  }
}

/**
 * Taken from https://stackoverflow.com/questions/4994201/is-object-empty
 * If you only need to handle ECMAScript5 browsers, you can use Object.getOwnPropertyNames instead of the hasOwnProperty loop:
 *  if (Object.getOwnPropertyNames(obj).length) return false
 * @param obj
 * @returns {boolean}
 * @see user.js 2 usages
 */
export function isEmptyObject (obj) {
  // null and undefined are "empty"
  if (obj == null) return true

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false
  if (obj.length === 0) return true

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false
  }

  return true
}

/**
 * takes in object path in dot notation and returns the value
 * @param {Object} object { a: { b: { c: { d: 'testVal' } } }
 * @param {String} pathString 'a.b.c.d'
 * @returns value at index, 'testVal'
 */
export const objectValByPath = (object, pathString) => {
  pathString = pathString.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  pathString = pathString.replace(/^\./, '') // strip a leading dot
  const arr = pathString.split('.')
  for (let i = 0, n = arr.length; i < n; ++i) {
    const key = arr[i]
    if (!key || !object) return
    if (key in object) {
      object = object[key]
    } else {
      return
    }
  }
  return object
}

/**
 * A more modern JavaScript and TypeScript implementation of a simple object to flat property map converter. It's using Object.entries to do a proper for of loop only on owned properties.
 * https://stackoverflow.com/questions/44134212/best-way-to-flatten-js-object-keys-and-values-to-a-single-depth-array/59787588
 *
 * @export
 * @param {*} object
 * @param {string} [keySeparator='.']
 * @returns
 */
export function toFlatPropertyMap (object, keySeparator = '.') {
  const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key
      if (value && typeof value === 'object') {
        flattenRecursive(value, property, propertyMap)
      } else {
        propertyMap[property] = value
      }
    }
    return propertyMap
  }
  return flattenRecursive(object)
}

/**
 * takes a path string and inserts it into the supplied Object
 * @param {String} objectPathStringsArray
 * @param {Object} obj
 * @returns {Object}
 */
export const objectPathsToObject = (objectPathStringsArray, obj = {}) => {
  if (objectPathStringsArray.length === 0) return obj
  for (const projectKey of objectPathStringsArray) { // Cache the path length and current spot in the object
    const path = projectKey.split('.')
    const length = path.length
    let current = obj
    // Loop through the path
    path.forEach((key, index) => {
      // If this is the last item in the loop, assign the value
      if (index === length - 1) {
        current[key] = key
      } else { // Otherwise, update the current place in the object
      // If the key doesn't exist, create it
        if (!current[key]) {
          current[key] = {}
        }
        // Update the current place in the objet
        current = current[key]
      }
    })
  }
  return obj
}

/**
 * converts an object to an array of object paths
 * @param {Object} object
 * @param {String} currentLocation
 * @param {Array} pathArray
 * @returns
 */
export const objectToObjectPathArrayOLD = (object, currentLocation = '', pathArray = []) => {
  for (const [key, value] of Object.entries(object)) {
    // consoleDev(thisFile, 'objectToObjectPathArray object:', object, 'key:', key, 'value:', value)
    if (typeof value === 'string') {
      pathArray.push(currentLocation + key)
    } else if (Array.isArray(value)) {
      pathArray.push(currentLocation + key)
    } else if (typeof value === 'object') {
      objectToObjectPathArray(value, currentLocation + key + '.', pathArray)
    }
  }
  return pathArray
}

/**
 * converts an object to an array of object paths
 * @param {Object} object
 * @param {String} currentLocation
 * @param {Array} pathArray
 * @returns
 */
export const objectToObjectPathArray = (object, currentLocation = String()) => {
  // consoleDev(thisFile, 'objectToObjectPathArray: object:', object, 'currentLocation', currentLocation)
  if (currentLocation && currentLocation.slice(-1) !== '.') currentLocation += '.'
  if (currentLocation === '.') currentLocation = String()
  return Object.keys(toFlatPropertyMap(object)).map(key => currentLocation + key)
}

const modeList = ['light', 'dark']

/**
 * Test a string to see if it is one of the valid modes.  e.g.  'light', 'dark'
 *
 * @param {*} mode
 * @returns
 */
export const isValidThemeMode = (mode) => {
  return !!(~modeList.indexOf(mode))
}

/**
 *  Will loop through all props looking for any that end in modeList[*] (e.g. 'light', 'dark').  If it finds one, and it is === theme.palette.mode,
 * it will set the peer main value to [palette.mode] value.  When done main will be equal to the mode.
 *
 * @param {*} themeRaw
 * @returns
 */
export const setThemeModeRaw = (themeRaw) => {
  const indexOfModeWanted = modeList.indexOf(themeRaw.palette?.type)
  if (!(~indexOfModeWanted)) return themeRaw // Mode is invalid
  const flatThemeRaw = toFlatPropertyMap(themeRaw)
  Object.keys(flatThemeRaw).forEach(themeProps => {
    const endPropIndex = themeProps.lastIndexOf('.')
    const endProp = themeProps.slice(endPropIndex + 1) // don't include '.'
    const pathMinusEndProp = themeProps.slice(0, endPropIndex) // don't include '.'
    const pathOfWantedProp = pathMinusEndProp + '.' + modeList[indexOfModeWanted]
    // Look for modes to interpret
    const indexOfMode = modeList.indexOf(endProp)
    if (~indexOfMode && (indexOfMode === indexOfModeWanted)) { // > -1
      const wantedValue = _.get(themeRaw, pathOfWantedProp)
      // Store our set our mode.  Set after build to preserve nesting
      // newProps.push([pathMinusEndProp, wantedValue])
      _.set(themeRaw, pathMinusEndProp + '.main', wantedValue)
    }
  })

  return themeRaw
}

/**
 *  Draft of converting theme modes into css variable syntax.  This has since been re-factored and split out into more testable helpers.
 *  Depreciated.
 *
 * @param {*} themePathsArray
 * @param {*} theme
 * @returns
 */
export const setThemeMode = (themePathsArray, theme) => {
  const themeConsts = {}
  themePathsArray.forEach(themeProp => {
    if (themeProp.includes('light') || themeProp.includes('main') || themeProp.includes('dark')) {
      // only inject the current theme type palette, light, main, or dark
      let cleanedThemePath = themeProp.replace()
      cleanedThemePath = cleanedThemePath.replace('.light', '')
      cleanedThemePath = cleanedThemePath.replace('.main', '')
      cleanedThemePath = cleanedThemePath.replace('.dark', '')
      const cleanedThemeProp = cleanedThemePath.replace(/\./g, '-')
      switch (theme.palette.mode) {
        case 'light':
          themeConsts['--' + cleanedThemeProp] = objectValByPath(theme, cleanedThemePath + '.light')
          break
        case 'dark':
          themeConsts['--' + cleanedThemeProp] = objectValByPath(theme, cleanedThemePath + '.dark')
          break

        default:
          // main
          themeConsts['--' + cleanedThemeProp] = objectValByPath(theme, cleanedThemePath + '.main')
          break
      }
    } else {
      themeConsts['--' + themeProp.replace(/\./g, '-')] = objectValByPath(theme, themeProp)
    }
  })
  return themeConsts
}

/**
 * Boolean for detecting Invalid Date
 *
 * @export
 * @param {*} d
 * @returns
 */
export function isValidDate (date) {
  return date instanceof Date && !isNaN(date)
}

/**
 * takes a date as a string and formats it to local settings
 * @param {string} dateToBeLocalized
 * @returns {string} localizedDateString
 */
export function localizeDateFormat (dateToBeLocalized, local, options) { return new Date(dateToBeLocalized).toLocaleString(local, options) }

/**
 * given a date range this function returns the difference in minutes and the offset from now
 * e.g. {start: Date, end: Date} where the start is 24 hours prior to now and end is now would return {start: "1440minutes", end: "0minutes"}
 * @param {Object} dateRange
 * @returns {Object} dynamic date range
 */
export const getDynamicDateRange = (dateRange) => {
  const returningRange = { start: dateRange.start, end: dateRange.end }
  const now = moment()
  const start = moment(dateRange.start)
  const end = moment(dateRange.end)
  const durationEndNow = moment.duration(now.diff(end)).as('minutes')
  const durationStartEnd = moment.duration(start.diff(end)).as('minutes')
  if (durationEndNow < 5) { // if the "end" date is within 5 minutes of now, assume now for the query
    returningRange.end = '0minutes'
  } else { // user may intend to omit the most recent entries
    returningRange.end = Math.abs(durationEndNow) + 'minutes'
  }
  returningRange.start = Math.abs(durationStartEnd) + 'minutes' // difference between start and end
  return JSON.stringify(returningRange)
}

/**
 * given a date range with dynamic strings it returns a relevant date range
 * e.g. {start: "1440minutes", end: "0minutes"} will return a date range of the last 24 hours
 * @param {Object} dateRange
 * @returns {Object} dateRange
 */
export const getNewDateRangesForSearch = (dateRange) => {
  if (~dateRange.end.indexOf('minutes')) {
    dateRange.end = dateRange.end.replace('minutes', '')
    dateRange.end = moment().subtract(dateRange.end, 'minutes')
  }
  if (~dateRange.start.indexOf('minutes')) {
    dateRange.start = dateRange.start.replace('minutes', '')
    dateRange.start = moment(dateRange.end).subtract(dateRange.start, 'minutes')
  }
  return dateRange
}

/**
 * Get parts of a date for de structured string building
 *
 * @param {*} date
 * @returns
 */
export const getDateParts = (dateFrom) => {
  if (!dateFrom) return
  return {
    year: dateFrom.getFullYear(),
    day: dateFrom.getDate(),
    month: dateFrom.getMonth() + 1 // 0 based index
  }
}

/**
 * Get parts of a date in UTC for de structured string building
 *
 * @param {*} date
 * @returns
 */
export const getDatePartsUTC = (dateFrom) => {
  if (!dateFrom) return
  const dateParts = {
    year: dateFrom.getUTCFullYear(),
    year2: dateFrom.getUTCFullYear().toString().substr(2, 2),
    day: dateFrom.getUTCDate(),
    day2: String(dateFrom.getUTCDate()).padStart(2, '0'),
    month: dateFrom.getUTCMonth() + 1, // 0 based index
    month2: String(dateFrom.getUTCMonth() + 1).padStart(2, '0') // 0 based index
  }
  return dateParts
}

/**
 * Returns date string according to RI Standards.
 *
 * @param {*} dateFrom
 * @param {*} separatorCharacter
 * @returns
 */
export const getRIDateString = (dateFrom, separatorCharacter = '-') => {
  const { year, day, month } = getDateParts(dateFrom)
  return year + separatorCharacter + (Number(month) < 10 ? '0' : String()) + month + separatorCharacter + (Number(day) < 10 ? '0' : String()) + day
}

/**
 * takes a json obj builds a string literal
 * @param filtersObjectAndOrArray
 * @returns {string} literal
 * @see ../app/client/pages/SalesOppBoiler.js 1 usage
 * @see ../app/controllers/graphql/cart.js 2 usages
 * @see ../app/controllers/graphql/user.js 2 usages
 * @see ../app/lib/cartHelper.js 8 usages
 * @see ../app/lib/webhookHelper.js 8 usages
 * @see ../app/client/pages/SalesOppBoiler.js 1 usage
 */
export function getFiltersString (filtersObjectAndOrArray) {
  if (!Array.isArray(filtersObjectAndOrArray)) filtersObjectAndOrArray = [filtersObjectAndOrArray]
  const filtersFormatted = filtersObjectAndOrArray.map(function (filter) {
    return '{field: ' + filter.field + ', operation: ' + filter.operation + ', value: "' + filter.value + '"}'
  })

  return filtersFormatted.join(',')
}

/**
 * Makes very common filter
 * @param id
 * @returns {string}
 */
export const filterById = (id) => {
  const filter = getFiltersString({
    field: 'id',
    operation: 'eq',
    value: id
  })

  return filter
}

/**
 * Get cookies from serverside req headers
 * @param key
 * @param req
 * @returns {*}
 */
export function getCookieFromServer (key, req) {
  if (!req) Error(consoleError(thisFile, 'getCookieFromServer Error empty req'))
  if (!req.headers.cookie) {
    return undefined
  }
  const rawCookie = req.headers.cookie
    .split(';')
    .find(c => c.trim().startsWith(key + '='))
  if (!rawCookie) {
    return undefined
  }
  return rawCookie.split('=')[1]
}

/**
 * This is a catchall for api calls that should be able to find errors anywhere in the error object
 * and it isn't finished.  There are some errors that still don't get reported right, Added the
 * stack, query, and variables objects to return, to diagnose.
 * @param error
 * @param errorStack
 * @param query
 * @param variables
 * @returns {object} errors
 */
export function handleGqlMakeQueryError (error, query, variables) {
  // consoleDev('functionHelper.handleGqlMakeQueryError')
  const response = error.response
  const failsafe = { errors: [{ message: JSON.stringify(error) }, query, variables] }
  if (!response) {
    // Probably thrown ourselves from sfGraphQL client, if there is a message, go with it.
    if (error.message) return { errors: [{ message: error.message }] }
    // Failsafe, return everything
    return failsafe
  }
  if (!response.errors) {
    // Single error needs to be converted to array.  A response needs to be either valid data or contain
    // errors array.
    if (response.error) {
      const errorsArr = [response.error]
      delete response.error
      response.errors = errorsArr
    } else {
      return failsafe
    }
  }
  const returnObj = Object.assign({}, response, error.request)
  // consoleDev('functionHelper.handleGqlMakeQueryError returnObj = ' + JSON.stringify(returnObj))
  return returnObj
}

/**
 * looks for null for undefined text and makes them an undefined type
 * @param arg
 * @returns {array}
 */
export function removeResolverArgsStringifiedNullAndUndefined (arg) {
  for (const key in arg) {
    if (arg[key] === 'null' || arg[key] === 'undefined') {
      arg[key] = undefined
    }
  }
  return arg
}
/**
 * Checks string array for dupe element and returns the first one it finds
 *
 * @export
 * @param {*} stringArrayToCheck
 * @returns
 */
export function dupeInStringArray (stringArrayToCheck) {
  // consoleDev(thisFile, 'dupeInStringArray: stringArrayToCheck', stringArrayToCheck)
  for (let i = 0; i < stringArrayToCheck.length; i++) {
    for (let j = i + 1; j < stringArrayToCheck.length; j++) {
      if (typeof stringArrayToCheck[i] !== 'string') throw new Error(thisFile, 'dupInStringArray: Non string element found.')
      if (stringArrayToCheck[i] === stringArrayToCheck[j]) { // got the duplicate element
        return stringArrayToCheck[i]
      }
    }
  }

// Read more: https://javarevisited.blogspot.com/2015/06/3-ways-to-find-duplicate-elements-in-array-java.html#ixzz6C9Xxx4bX
}

/**
 * Makes sure object is not a reference to another
 * @param {*} objRef
 */
export function jsonCopy (objRef) {
  return JSON.parse(JSON.stringify(objRef))
}

export function copyToClipboard (document, str) {
  if (!(document && str)) return
  const el = document.createElement('textarea') // Create a <textarea> element
  el.value = str // Set its value to the string that you want copied
  el.setAttribute('readonly', '') // Make it readonly to be tamper-proof
  el.style.position = 'absolute'
  el.style.left = '-9999px' // Move outside the screen to make it invisible
  document.body.appendChild(el) // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false // Mark as false to know no selection existed before
  el.select() // Select the <textarea> content
  document.execCommand('copy') // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el) // Remove the <textarea> element
  if (selected) { // If a selection existed before copying
    document.getSelection().removeAllRanges() // Unselect everything on the HTML document
    document.getSelection().addRange(selected) // Restore the original selection
  }
}

export function downloadFile (document, href, fileName) {
  const element = document.createElement('a')
  element.style.display = 'none'
  element.href = href
  element.title = fileName
  element.type = 'application/csv'
  element.target = '_self'
  element.download = fileName
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

/**
 * from: https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
 * This will ALWAYS return a float!
 * @param {*} n
 * @param {*} digits
 */
export function roundTo (n, digits) {
  let negative = false
  if (digits === undefined) {
    digits = 0
  }
  if (n < 0) {
    negative = true
    n = n * -1
  }
  const multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = parseFloat((Math.round(n) / multiplicator).toFixed(digits))
  if (negative) {
    n = (n * -1).toFixed(digits)
  }
  return parseFloat(n)
}

/**
 * returns the array of location permissions if found else false
 * @returns {Array || Boolean} ['permissions'] || false
 */
function getLocationPermissions (location, accessRules) {
  const rule = accessRules && accessRules.find && accessRules.find(rule => rule.location === location)
  return (rule && rule.permissions) || false // dont check length, only checking if the rule exists, it can be []
}

/**
 * test if given user has access to the given location based on the given accessRules
 * @param {*} location
 * @param {*} accessRules
 * @param {*} user
 * @returns {Boolean}
 */
export function hasAccess (location, accessRules, user) { // eslint-disable-line no-unused-vars
  if (accessRules?.length === 0) return false
  const locationPermissions = getLocationPermissions(location, accessRules) // get location will return array or false
  if (!locationPermissions || locationPermissions.length === 0) return true // there is no rule or the rule has no permissions
  // for every permission in locationPermissions user.permissions must include every one
  return locationPermissions.every && locationPermissions.every(permission => user.permissions.includes(permission))
}
/**
 * used to determine if user has the role of roleNameToCheck
 * @param {Object} user
 * @param {String} roleNameToCheck
 * @returns Boolean
 */
export function hasUserRole (user, roleNameToCheck) {
  if (!user?.roles?.length) return false
  for (const role of user.roles) {
    if (role.name === roleNameToCheck) return true
  }
  return false
}

/**
 * The roundTo function guarantees to return a float.  This simply calls that one in turn and returns the results as a string.
 * For convenience, call this instead of String(roundTo(n,x)).toFixed(x)
 * @param {*} n
 * @param {*} digits
 */
export function roundToFixed (n, digits) {
  return roundTo(n, digits).toFixed(digits)
}

/**
 *
 *
 *  a11yProps is material-ui helper function found in their docs.
 *  It's just a function for making a dynamic object and using them as props, to reduce code size.
 * https://material-ui.com/components/tabs/
 *
 * https://stackoverflow.com/questions/57305891/how-to-show-contents-inside-a-tab
 * @param {*} index
 * @returns
 */
export const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

/**
 * Recursive function to return all values of provided key with path, level, and messages
 * @param {*} searchObject
 * @param {*} searchKey
 * @param {*} levels
 * @param {*} pathString
 * @param {*} currentLevel
 */
export const findValuesByKey = (searchObject, searchKey, levels = 3, pathString = String(), currentLevel = 0) => {
  // const defaultValueObject = {
  //   level: -1,
  //   value: undefined,
  //   path: undefined
  // }
  const returnValuesObject = {
    values: [],
    msg: String()
  }
  if (currentLevel > levels) {
    returnValuesObject.msg = 'Max level reached.'
    return returnValuesObject
  }
  if (typeof searchObject === 'object' && searchObject !== null) {
    for (const [key, value] of Object.entries(searchObject)) {
      if (key === searchKey) {
        returnValuesObject.values.push({
          level: currentLevel,
          value: value,
          path: pathString
        })
      } else {
        const newPath = pathString ? pathString.concat('.' + key) : key
        const resultValuesObject = findValuesByKey(searchObject[key], searchKey, levels, newPath, currentLevel + 1)
        if (resultValuesObject.values.length || resultValuesObject.msg) {
          returnValuesObject.values = returnValuesObject.values.concat(resultValuesObject.values)
          returnValuesObject.msg += (returnValuesObject.msg ? ', ' : String()) + resultValuesObject.msg
        }
      }
    }
  }
  return returnValuesObject
}

/**
 *
 *
 * @param {*} obj
 * @returns
 */
export function isIterable (obj) {
  // checks for null and undefined
  if (obj == null) {
    return false
  }
  return typeof obj[Symbol.iterator] === 'function'
}

/**
 *
 *
 * @param {*} fromString
 * @param {*} DEBUG
 * @returns
 */
export const getFirstNumberFromString = (fromString, DEBUG = false) => {
  if (DEBUG) consoleDev(thisFile, 'getFirstNumberFromString fromString:', fromString)
  if (!fromString) return
  if (!isIterable(fromString)) return

  let index = 0
  for (const character of fromString) {
    if (DEBUG) consoleDev(thisFile, 'getFirstNumberFromString character:', character)
    if (isNumber(character)) {
      if (DEBUG) consoleDev(thisFile, 'isNumber returning:', { character, index })
      return { character, index }
    } else if (DEBUG) consoleDev(thisFile, '! isNumber')
    index++
  }
}
/**
 * Simple Integer to letter converter
 *
 * @param {*} number
 * @param {boolean} [capital=true]
 * @returns
 */
export const getLetterFromNumber = (number, capital = true) => {
  const letter = (number + 9).toString(36).toUpperCase()
  return capital ? letter.toUpperCase() : letter
}

/**
   * formats the phone number for display
   * NOTE: this function is for static number strings not for input fields that have variable length
   * @param {String} phone
   * @returns formatted phone number as xxx-xxx-xxxx
   */
export const formatPhoneNumber = (phone) => {
  if (!phone) return
  if (typeof phone !== 'string') return
  let phoneString = phone
  phoneString = phoneString.replace('+1', '')// remove leading +1
  let cleaned = phoneString.replace(/\D/g, '') // remove anything that is not a number
  if (cleaned.length > 10) { // return only the last 10 digits so that 1 (xxx) xxx-xxxx only returns xxx-xxx-xxxx
    cleaned = cleaned.substring(cleaned.length - 10)
  }
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/) // match the first 3 digits, the middle 3 digits, and the last 4 digits
  let formattedPhoneString = ''
  if (match) {
    formattedPhoneString = match[1] + '-' + match[2] + '-' + match[3] // format to xxx-xxx-xxxx
  } else {
    formattedPhoneString = phone // if it doesn't match, just return the original phone input
  }
  return formattedPhoneString
}

/**
 * Worldpay uses an exponent value instead of decimal point.  This function will convert a
 * floating point to an integer with said exponent.  e.g.  10.99 x2  becomes 1099
 * @param {*} incomingFloat
 * @param {*} exponent
 */
export const float2ExponentInt = (incomingFloat, exponent = 2) => {
  return parseInt(roundToFixed(incomingFloat, exponent).replace('.', ''))
}

/**
 * @description splits a string on its spaces and capitalizes each word, ignoring the case of the rest of the chars, and rejoins them
 * @param {String} text
 */
export const capitalizeString = (text) => {
  const wordsArray = text.split(' ')
  const capWordsArray = wordsArray.map(str => str.charAt(0).toUpperCase() + str.substring(1))
  const returnString = capWordsArray.join(' ')
  return returnString
}

/**
 * Capitalizes first character of string and returns said string.
 *
 * @param {*} charString
 * @returns
 */
export const capitalizeFirst = (charString) => {
  if (typeof charString !== 'string') return charString
  return charString.charAt(0).toUpperCase() + charString.slice(1)
}

/**
 * Get quoted 'value:' part of query string.  FAST IS KEY!
 * @param {*} query
 */
export const getKeyValueFromQuery = (query, key) => {
  const keyIndex = query.indexOf(key + ':')
  if (keyIndex === -1) return
  const firstQuote = query.indexOf('"', keyIndex + 1)
  if (firstQuote === -1) return
  const secondQuote = query.indexOf('"', firstQuote + 1)
  if (secondQuote === -1) return
  return query.substr(firstQuote + 1, secondQuote - (firstQuote + 1))
}

/**
 * Get returnList object part of query string.  FAST IS KEY!
 * @param {*} query
 */
export const getReturnListFromQuery = (query) => {
  const lastCloseBracket = query.lastIndexOf('}')
  if (lastCloseBracket === -1) return
  const next2lastCloseBracket = query.lastIndexOf('}', lastCloseBracket - 1)
  if (next2lastCloseBracket === -1) return
  const lastCloseParen = query.lastIndexOf(')')
  if (lastCloseParen === -1) return
  const bracketAfterLastCloseParen = query.indexOf('{', lastCloseParen + 1)
  if (bracketAfterLastCloseParen === -1) return

  return query.substr(bracketAfterLastCloseParen + 1, next2lastCloseBracket - (bracketAfterLastCloseParen + 1))
}

/**
 * This function aims to be the most efficient way to look for certain queries
 * to facilitate using Redis cache.  FAST IS KEY!
 * @param {*} query
 */
export const getQueryProps = (query) => {
  query = query.trim()
  const firstChar = query.substr(0, 1).toLowerCase()
  const firstBracket = query.indexOf('{', firstChar + 1)
  if (firstBracket === -1) return
  if (firstChar === 'm') {
    const secondBracket = query.indexOf('{', firstBracket + 1)
    if (secondBracket === -1) return
    const firstParen = query.indexOf('(', secondBracket + 1)
    if (firstParen === -1) return
    const closeParen = query.indexOf(')', firstParen)
    const parameters = query.substr(firstParen + 1, closeParen - (firstParen + 1))
    const name = query.substr(secondBracket + 1, firstParen - (secondBracket + 1)).trim()
    return { type: firstChar, name, value: getKeyValueFromQuery(query, 'value'), parameters, returnList: getReturnListFromQuery(query) }
  } else {
    const firstParen = query.indexOf('(', firstBracket + 1)
    if (firstParen === -1) return
    const closeParen = query.indexOf(')', firstParen)
    const parameters = query.substr(firstParen + 1, closeParen - (firstParen + 1))
    const name = query.substr(firstBracket + 1, firstParen - (firstBracket + 1)).trim()
    return { type: firstChar, name, value: getKeyValueFromQuery(query, 'value'), parameters, returnList: getReturnListFromQuery(query) }
  }
}

export const returnError = async (returnObject) => {
  if (returnObject && (returnObject.errors || returnObject.error)) return true
}

/**
 * Nice ways to stringify output
 * @param {*} obj
 */
export const jsonStr = (obj, limit = undefined) => {
  let returnString = ''

  try {
    returnString = JSON.stringify(obj, null, 4)
  } catch (error) {
    if (error.message.substr(0, 37) === 'Converting circular structure to JSON') {
      returnString = 'circular - inspect:\n'
      returnString += util.inspect(obj)
    } else {
      consoleError('functionHelper.jsonStr error: [', error.message, ']')
      consoleError('functionHelper.jsonStr obj: ', obj)
      returnString = error.message
    }
  }

  if (limit) return returnString.substr(0, limit)
  return returnString
}

/**
 * Simple delay
 * @param {*} ms
 */
export const delay = (ms) => { return new Promise(function (resolve) { return setTimeout(resolve, ms) }) }

/**
 * Adds nice countdown console for delay
 * @param {*} msg
 * @param {*} ms
 * @param {*} callback
 * @param {*} payload Either array of functions to call and return with promise.all. First element will be function to call with array of results from the rest.
 * Or Simple return value (if not an array)
 */
export const delayCountdown = async (msg, ms, callback, payload) => {
  if (isNaN(ms)) {
    if (!isNaN(Number(msg))) { // if directly replacing delay()
      ms = Number(msg)
      msg = 'Defaulted to delay(ms)'
    } else {
      throw new Error('functionHelper.delayCountdown <' + ms + '> is NaN')
    }
  }
  let step = 1000
  if (ms <= 1000) step = 100
  let counter = parseInt(Number(ms) / step)
  consoleWarn(msg, ' DELAY: ', counter, ' ', step === 1000 ? 'seconds' : 'tenths of a second')
  const message = msg + ' DELAY: ' + counter
  callback && callback(message)
  while (counter--) {
    await delay(step)
    consoleWarn(msg, ' DELAY: ', counter, ' ', step === 1000 ? 'seconds' : 'tenths of a second')
    callback && callback(message)
  }

  // Payload now is array of functions to call and return with promise.all
  // First element will be function to call with array of results from the rest

  // Old was to simply return value
  if (Array.isArray(payload)) {
    const returnFunction = payload.shift()
    const payloadResultArray = await Promise.all(payload)
    return returnFunction(payloadResultArray)
  } else {
    return payload
  }
}

/**
 *
 * Provides a wrapper for authentication messages so we can control when / where reveal information
 * and to what depth since this is a security risk.
 *
 * @param {*} fileName
 * @param {*} functionName
 * @param {*} message
 * @returns
 */
export const authError = (fileName, functionName, message) => {
  if (variantToBool(process.env.RI_DEBUG) || variantToBool(process.env.AUTH_ERROR_DEBUG)) {
    return fileName + ' ' + functionName + ' ' + message
  } else {
    return 'You are not authenticated!'
  }
}
/**
 *
 *
 * @param {*} ms
 * @param {*} promise
 * @returns
 */
export const promiseTimeout = (ms, promise) => {
  // Create a promise that rejects in <ms> milliseconds
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      reject(new Error('Timed out in ' + ms + 'ms.'))
    }, ms)
  })

  // Returns a race between our timeout and the passed in promise
  return Promise.race([
    promise,
    timeout
  ])
}

/**
 * Catch all function for any variable that must covert to boolean.
 * @param {*} envVariable
 * @returns {Boolean} primitive
 */
export const variantToBool = (envVariable) => {
  if (!envVariable) return false // Not set
  if (typeof envVariable === 'boolean') return envVariable
  // Checked for unset and boolean primitives.  The rest should be strings.
  if (!(typeof envVariable === 'string')) envVariable = jsonStr(envVariable)
  // All strings default to true unless one of the following.
  switch (envVariable.toLowerCase()) {
    case 'squat':
    case 'bubkis':
    case 'bupkis':
    case 'off':
    case 'offline':
    case 'false':
    case 'f':
    case 'no':
    case 'n':
    case 'not':
    case 'nill':
    case 'zero':
    case 'nada':
    case 'negative':
    case 'zilch':
    case 'null':
    case 'empty':
    case 'nowayhosay':
    case 'elvishasleftthebuilding':
    case 'leftthetracks':
    case 'undefined':
    case '0':
    case 0:
    case null:
    case undefined:
    case 'nan': // Result from Number() when Not A Number
    case String(): // Empty
    case 'n/a': // Human reference to "Not Applicable"
      return false
    default:
      return true
  }
}

// eslint-disable-next-line no-console
let useWhatChanged = (deps) => { console.log('consoleDevEffectDeps deps:\n', deps) }
if (variantToBool(process.env.RI_DEBUG)) useWhatChanged = require('@simbathesailor/use-what-changed').useWhatChanged
export const consoleDevEffectDeps = useWhatChanged

export const serverLog = (models, { context, message, data }) => {
  try {
    models.Log.create({
      context,
      message,
      data
    })
  } catch (error) {
    consoleError('csvStore.errorLog: ', error.message)
  }
}

const consoleReset = '\x1b[0m'
const consoleRed = '\x1b[31m'
const consoleGreen = '\x1b[32m'
const consoleYellow = '\x1b[33m'
const consoleBlue = '\x1b[34m'
const consoleMagenta = '\x1b[35m'
const consoleCyan = '\x1b[36m'

// Good alternate example
// console.log('%c Oh my heavens! ', 'background: #222; color: #bada55')

const consoleFireTest = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' || process.env.ENV === 'development' || process.env.ENV === 'test' || variantToBool(process.env.RI_DEBUG)
/**
 * Wraps normal console.log with if development and MAKES VERY NOTICEABLE.
 */
export function consoleATTN () {
  const argsArray = Array.prototype.slice.call(arguments)
  if (consoleFireTest) {
    console.info('\x1b[35m\n\n\n###############################################################################################################')
    console.info('###########################################  ATTENTION  #######################################################')
    console.info('###############################################################################################################')
    console.info(consoleCyan)
    console.info.apply(this, arguments) // non es6
    console.info(consoleReset) // reset
    console.info('###############################################################################################################')
    console.info('###############################################################################################################\n\n\n' + consoleReset)
  }
  return argsArray.join(' ')
}
/**
 * wrapper for server logging
 *
 * @param {*} { level = 'info', message = String() }
 */
export const logger = async ({ level = 'info', message = String(), options = { utNow: undefined } }) => { // eslint-disable-line no-unused-vars
  return null
  /*
  DO NOT USE consoleX FUNCTIONS HERE!!!
  */
  // console.log(thisFile, `logger level = ${level}, message:`, message)
  // if (!clientWindow) {
  //   await connectToMongooseDb('helper.js')
  //   console.log(thisFile, 'logger logging')
  //   const { createLog } = require('../../graphql/components/log').LogResolvers.Mutation
  //   console.log(thisFile, 'logger createLog', createLog)

  //   const user = undefined // To show params expected for reference
  //   if (typeof message !== 'string') message = jsonStr(message)
  //   let result
  //   try {
  //     if (!process.env.NO_DB_LOGGING) result = await createLog(undefined, { input: { timestamp: moment(options.utNow).toISOString(), level, message } }, { user })
  //   } catch (error) {
  //     console.log(thisFile, 'logger error:', error.message)
  //   }
  //   console.log(thisFile, 'logger result:', result)
  // }
}
/**
 * Wraps normal console.log with if development.
 */
export function consoleDev () {
  const argsArray = Array.prototype.slice.call(arguments)
  // console.log('helper.consoleError argsArray:', argsArray)
  const arrayStringified = []
  argsArray.forEach((element) => arrayStringified.push(jsonStr(element)))
  if (consoleFireTest) {
    console.log(consoleYellow) // eslint-disable-line no-console
    if (logger && (process.env.NODE_ENV === 'production' || FAKE_NODE_ENV_PRODUCTION) && !clientWindow) {
      logger({
        level: 'debug',
        message: argsArray
      })
    }
    // eslint-disable-next-line no-console
    console.log.apply(this, arguments) // non es6
    // eslint-disable-next-line no-console
    console.log(consoleReset) // reset
  }
  return argsArray.join(' ')
}

/**
 * Wraps normal console.log with if development.
 */
export function consoleInfo () {
  const argsArray = Array.prototype.slice.call(arguments)
  // console.log('helper.consoleError argsArray:', argsArray)
  const arrayStringified = []
  argsArray.forEach((element) => arrayStringified.push(jsonStr(element)))
  // if (consoleFireTest) {
  console.info(consoleBlue)
  if (logger && (process.env.NODE_ENV === 'production' || FAKE_NODE_ENV_PRODUCTION) && !clientWindow) {
    logger({
      level: 'debug',
      message: argsArray
    })
  }
  console.info.apply(this, arguments) // non es6
  console.info(consoleReset) // reset
  // }
  return argsArray.join(' ')
}

/**
 * Replaces consoleX. Only returns string.  NO PRINTING
 */
export function consoleString () {
  const argsArray = Array.prototype.slice.call(arguments)
  return argsArray.map(arg => JSON.stringify(arg, null, 2)).join(' ')
}

/**
 * Wraps normal console.info.
 */
export async function consoleInfoAsync () {
  const argsArray = await Array.prototype.slice.call(arguments)
  const worker = async (argsArray) => {
    console.info(consoleBlue)
    if (logger && (process.env.NODE_ENV === 'production' || FAKE_NODE_ENV_PRODUCTION) && !clientWindow) {
      logger({
        level: 'info',
        message: argsArray
      })
    }
    await console.info.apply(this, arguments)
    await console.info(consoleReset) // reset
    return argsArray.join(' ')
  }
  return worker(argsArray)
}

/**
 * Wraps normal console.warn with if development.
 */
export function consoleWarn () {
  const argsArray = Array.prototype.slice.call(arguments)
  // console.log('helper.consoleError argsArray:', argsArray)
  const arrayStringified = []
  argsArray.forEach((element) => arrayStringified.push(jsonStr(element)))
  console.warn(consoleMagenta) // NOT FOR BROWSER
  if (logger && (process.env.NODE_ENV === 'production' || FAKE_NODE_ENV_PRODUCTION) && !clientWindow) {
    logger({
      level: 'warn',
      message: argsArray
    })
  }
  console.warn.apply(this, arguments)
  console.warn(consoleReset) // reset
  return argsArray.join(' ')
}

/**
 * Wraps normal console.error with if development.
 */
export function consoleError () {
  const argsArray = Array.prototype.slice.call(arguments)
  if (!clientWindow) console.error(consoleRed)
  if (logger && (process.env.NODE_ENV === 'production' || FAKE_NODE_ENV_PRODUCTION) && !clientWindow) {
    logger({
      level: 'error',
      message: argsArray
    })
  }
  if (process.env.NODE_ENV === 'test') {
    console.error(consoleRed)
    console.error.apply(this, arguments)
    console.error(consoleReset)
  } else {
    console.error.apply(this, arguments)
  }
  // if (process.env.NODE_ENV === 'test' || !clientWindow) {
  // console.log(consoleRed)
  // console.log.apply(this, arguments)
  // console.log(consoleReset)
  // } else {
  //   console.error.apply(this, arguments)
  // }
  if (!clientWindow) console.error(consoleReset) // reset
  return argsArray.join(' ')
}

/**
 * Wraps normal console.error with if development.
 */
export function consoleLoggerError () {
  // Should be same as consoleError but without logging.
  // Not DRY because want TOTALLY separate.
  // If in doubt, copy above function and remove logging
  const argsArray = Array.prototype.slice.call(arguments)
  if (!clientWindow) console.error(consoleRed)
  if (process.env.NODE_ENV === 'test') {
    console.error(consoleRed)
    console.error.apply(this, arguments)
    console.error(consoleReset)
  } else {
    console.error.apply(this, arguments)
  }
  // if (process.env.NODE_ENV === 'test' || !clientWindow) {
  // console.log(consoleRed)
  // console.log.apply(this, arguments)
  // console.log(consoleReset)
  // } else {
  //   console.error.apply(this, arguments)
  // }
  if (!clientWindow) console.error(consoleReset) // reset
  return argsArray.join(' ')
}

/**
 * Allow comma notation for Error
 */
export function throwError () {
  const errorArray = Array.prototype.slice.call(arguments)
  const arrayStringified = []
  errorArray.forEach((element) => arrayStringified.push(jsonStr(element)))
  throw new Error(arrayStringified.join(' '))
}

export function consoleLog (string, limit, onString) {
  // Writes in green
  const consoleColor = consoleGreen
  if (limit === undefined) limit = 0
  if (onString === undefined) onString = ''
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    if (onString) {
      const onStart = string.indexOf('=')
      if (onStart > -1) {
        console.log(consoleColor + string.substr(0, onStart + 1) + consoleReset) // eslint-disable-line no-console
        let index = string.indexOf(onString, onStart + 1)
        if (index > -1) {
          let nextIndex = string.indexOf(onString, index + 1)
          console.log(consoleColor + index + ' | ' + nextIndex + consoleReset) // eslint-disable-line no-console
          while (index !== -1) {
            console.log(consoleColor + string.substr(index, (nextIndex > index ? Math.min(limit, (nextIndex - index)) : limit)) + consoleReset) // eslint-disable-line no-console
            index = nextIndex
            if (nextIndex !== -1) nextIndex = string.indexOf(onString, nextIndex + 1)
            console.log(consoleColor + index + ' | ' + nextIndex + consoleReset) // eslint-disable-line no-console
          }
        } else {
          console.log(consoleColor + (limit > 0 ? string.substr(onStart + 1, limit) : string.substr(onStart + 1)) + consoleReset) // eslint-disable-line no-console
        }
      } else {
        console.log(consoleColor + (limit > 0 ? string.substr(0, limit) : string) + consoleReset) // eslint-disable-line no-console
      }
    } else {
      console.log(consoleColor + (limit > 0 ? string.substr(0, limit) : string) + consoleReset) // eslint-disable-line no-console
    }
  }
}
/**
 * Used to pick a random number from a pool.  Min and max numbers are included in the pool
 *
 * @export
 * @param {*} min
 * @param {*} max
 * @returns
 */
export function getRandomIntInclusive (min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min // The maximum is inclusive and the minimum is inclusive
}

export function getSampleData (minCategories, maxCategories, minSubcategories, maxSubcategories) {
  let subcategorySamples = []
  const categorySamples = []

  function getDescription () {
    switch (getRandomIntInclusive(1, 3)) {
      case 1:
        return ''
      case 2:
        return 'this is a short description'
      case 3:
        return 'this is a much longer description than a short description would usually be.  I hope it will help show different layout possibilities.'
    }
  }

  function getName () {
    switch (getRandomIntInclusive(1, 5)) {
      case 1:
        return 'Name'
      case 2:
        return 'LongerName'
      case 3:
        return 'TwoWord Naming'
      case 4:
        return 'Three Word Naming'
      case 5:
        return 'Reallylongname Withmany Separate Wordsthat Cango Onandonandonandonandonandsoforth'
    }
  }

  for (let idx2 = 0; idx2 < getRandomIntInclusive(minCategories, maxCategories); idx2++) {
    subcategorySamples = []
    for (let idx1 = 0; idx1 < getRandomIntInclusive(minSubcategories, maxSubcategories); idx1++) {
      subcategorySamples.push({
        id: 'subCat' + idx1,
        name: getName(),
        description: getDescription()
      })
    }
    // debugObj(subcategorySamples, 'subcategorySamples')
    categorySamples.push({
      id: 'Cat' + idx2,
      name: getName(),
      description: getDescription(),
      subcategories: subcategorySamples
    })
  }

  // debugObj(categorySamples, 'categorySamples')
  return categorySamples
}

/**
 * Extracts html tag content out.  e.g.  <script>
 * It will use the last set if there are more than one in the content.
 *
 * @param {*} content  Any String.
 * @param {*} tag e.g. script  No gt or lt.
 * @param {*} idText  The id of the tag (required)
 * @returns
 */
export const getHtmlTagContentById = (content, tag, idText) => {
  // If no Id, no content
  const idString = `id="${idText}"`
  const indexOfId = content.indexOf(idString)
  // Get id attribute
  if (indexOfId > -1) {
    const openTagString = `<${tag}`
    const closeTagString = `</${tag}>`
    let openTagIndex = content.indexOf(openTagString)
    let nextOpenTagIndex = content.indexOf(openTagString, openTagIndex + 1)
    // Goes to the last one if there are more than one
    while (nextOpenTagIndex > -1 && nextOpenTagIndex < indexOfId) {
      openTagIndex = nextOpenTagIndex
      nextOpenTagIndex = content.indexOf(openTagString, openTagIndex + 1)
    }
    const openTagEndIndex = content.indexOf('>', openTagIndex + 1) + 1
    const closeTagIndex = content.indexOf(closeTagString, openTagEndIndex)
    if (openTagEndIndex > -1 && closeTagIndex > -1) {
      return content.substr(openTagEndIndex, closeTagIndex - openTagEndIndex)
    }
  }
}

/**
 * Either returns the json or throws an error with the original body text in it. (e.g. Server error message.)
 *
 * @param {*} text
 */
export const getJsonWithErrorHandling = (text, { throwError = true } = {}) => {
  // consoleDev(thisFile, 'getJsonWithErrorHandling text:', text)
  try {
    const parsedBody = JSON.parse(text)
    // consoleDev(thisFile, 'getJsonWithErrorHandling parsedBody:', parsedBody)
    return parsedBody
  } catch (error) {
    if (throwError) {
      throw new Error(consoleError('\ngetJsonWithErrorHandling JSON.parse error:', error.message, ' \nbodyText:', text))
    }

    return { error: error.message, text }
  }
}

/**
 * Does not error out on invalid string.  Instead returns it.  Like Objects.
 *
 * @param {*} text
 * @returns
 */
export const jsonPar = (text) => {
  try {
    return text && JSON.parse(text)
  } catch (error) {
    return text
  }
}

/**
 *
 *
 * @param {*} text
 * @param {*} title
 * @param {*} x
 * @param {*} y
 * @returns
 */
export const debugObjWindow = (text, title, x, y) => { // eslint-disable-line no-unused-vars
  if (process.env.NODE_ENV === 'development') {
    try {
      const newWindow = clientWindow && clientWindow.open('', title, 'height=500,width=400,resizable=yes,scrollbars=yes,toolbar=yes,status=yes')
      newWindow.document.open()
      if (text === '[object] [Object]') text = jsonStr(text)
      newWindow.document.write(
        '<title>' + title + '</title>' +
        '<html><head></head><body><pre>' +
        text +
        '</pre></body></html>'
      )
      newWindow.document.close()
      return newWindow
    } catch (error) {
      if (error.message === 'window is not defined') {
        console.error('WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW ' + x + ' ' + title + ' ' + y + ' WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW')
        console.error(text)
        console.error('WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW ' + x + ' ' + title + ' ' + y + ' WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW')
      } else {
        console.error(error.message)
      }
    }
  }
}

/**
 *
 *
 * @param {*} stream
 * @returns
 */
export const streamToString = (stream) => {
  // consoleDev(thisFile, 'streamToString stream: ', stream)
  if (!stream) throw new Error(thisFile + 'streamToString !stream')
  const chunks = []
  return new Promise((resolve, reject) => {
    stream.on('data', chunk => chunks.push(chunk))
    stream.on('error', () => {
      consoleError(thisFile, 'streamToString error: ')
      return reject
    })
    stream.on('end', () => resolve(Buffer.concat(chunks).toString('base64')))
  })
}

/**
 * Sorts and Object by the passed key or first in keys() array if omitted
 *
 * @param {Object} objectToSort - Object to sort
 * @param {string} [sortKey] - Key to sort by
 * @returns
 */
export const objectSort = (objectToSort, sortKey) => {
  if (!objectToSort) return objectToSort
  if (!sortKey) sortKey = Object.keys(objectToSort)[0]
  const objectToSortCopy = deepCopy(objectToSort)
  try {
    const returnObject = objectToSortCopy.sort((a, b) => {
      if (typeof a[sortKey] === 'string' && typeof b[sortKey] === 'string') {
        const lowerA = a[sortKey].toLowerCase()
        const lowerB = b[sortKey].toLowerCase()
        return lowerA > lowerB ? 1 : -1
      } else {
        return a > b ? 1 : -1
      }
    })
    return returnObject
  } catch (error) {
    consoleError(thisFile, 'objectSort error:', error.message)
    return objectToSort
  }
}

/**
 * Remove any characters that can cause errors
 *
 * @param {*} str
 * @returns
 */
export const makeFilenameSafeString = str => {
  // eslint-disable-next-line no-useless-escape
  const illegalRe = /[\/\?<>\\:\*\|":]/g
  // eslint-disable-next-line no-control-regex
  const controlRe = /[\x00-\x1f\x80-\x9f]/g
  const reservedRe = /^\.+$/
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i
  const replacement = ''
  const sanitized = str
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
  return sanitized
}

/**
 * Converts questionText to questionSlug with shared logic
 *
 * @param {*} questionText
 * @returns
 */
export const makeSlug = (text) => {
  // consoleDev(thisFile, 'questionToSlug.questionText: ', questionText)
  /*
      slugify('some string', {
        replacement: '-',    // replace spaces with replacement
        remove: null,        // regex to remove characters
        lower: true,         // result in lower case
      })

      Ty likes underscore, limit to 10 words, remove common words like 'the', 'a', 'and', and punctuation
  */
  let slug = text
  slug = slug.replace(/\bthe\b/g, ' ')
  slug = slug.replace(/\ba\b/g, ' ')
  slug = slug.replace(/\band\b/g, ' ')
  // consoleDev(thisFile, 'questionToSlug.slug: ', slug)
  slug = slugify(slug, {
    replacement: '_',
    remove: /[*+~.,()'"!?:@]/g,
    lower: true
  })
  // consoleDev(thisFile, 'questionToSlug.slug: ', slug)
  let count = 0
  let index = 0
  let end = false
  while (count < 10) {
    index = slug.indexOf('_', index + 1)
    if (index === -1) end = true
    ++count
  }
  if (!end) slug = slug.substr(0, index)
  return slug
}

/**
 * Modifies the object pointer (if not a string) and returns the string version of questionSlug
 *
 * @param {object | string} incomingParameter
 * @returns {string}
 */
export const autoSlug = (incomingParameter) => {
  let returnSlug
  if (typeof incomingParameter === 'object') {
    const keys = Object.keys(incomingParameter)
    if (!keys.indexOf('questionText')) throw new Error(thisFile + 'No questionText on object keys')
    if (incomingParameter.questionText === String()) throw new Error(thisFile + 'questionText empty')
    // if (!keys.indexOf('questionSlug')) throw new Error(thisFile + 'No questionSlug on object keys')
    const slug = makeSlug(incomingParameter.questionText)
    if (!incomingParameter.questionSlug) incomingParameter.questionSlug = slug
    returnSlug = slug
  } else if (typeof incomingParameter === 'string') {
    returnSlug = makeSlug(incomingParameter)
  } else {
    const errorMessage = 'incomingParameter not string or object'
    consoleError(thisFile, errorMessage)
    throw new Error(thisFile + errorMessage)
  }
  return returnSlug
}

/**
 * Returns a zero padded displayId for sorting
 *
 * @param {string} idString In the form XXX-0, XXX-00, or XXX-000
 * @param {object} options  Contains separator character default '-'
 */
export const zeroPadId = (idString, options) => {
  const {
    separator = '-',
    outLength = 4
  } = options || {}

  if (!(idString && typeof idString === 'string')) return

  const separatorIndex = idString.indexOf(separator)

  // Use index to get counter
  const counter = !isNaN(Number(idString.substr(separatorIndex + 1))) && Number(idString.substr(separatorIndex + 1))

  // re-write idString with padded counter string and return
  if (counter) return idString.substr(0, separatorIndex + 1).concat(String(counter).padStart(outLength, '0'))
}

// export const baseUrl = process.env.BASE_URL ? process.env.BASE_URL : 'bbTest'
export const baseUrl = process.env.BASE_URL ? process.env.BASE_URL : (process.env.VERCEL_URL ? 'https://' + process.env.VERCEL_URL : 'http://localhost:3000')
export const baseUrlNoProtocol = baseUrl.substr(baseUrl.indexOf('//') + 2) // everything after first //  (http:// or https://)
export const baseContext = baseUrl.includes('pro.caseopp.com') ? 'pro' : (baseUrl.includes('sta.caseopp.com') ? 'sta' : (baseUrl.includes('demo.caseopp.com') ? 'demo' : 'dev'))
export const locationHost = (process.env.PORT && process.env.RDEVS_IP) ? process.env.RDEVS_IP + ':' + process.env.PORT : baseUrl
export const locationProtocol = (process.env.PORT && process.env.RDEVS_IP) ? 'http:' : String()
export const adminPortalBaseUrl = baseUrl.includes('pro.caseopp.com') ? 'https://reciprocityadmin.com' : null // Putting in helper since we will eventually want to split between staging, production, and local
export const baseApiUrl = process.env.BASE_API_URL ? process.env.BASE_API_URL : baseUrl + '/api'

/**
 *  Used in UserNotifications when it as adding displayId to the message
 *
 * @param {*} testWithDisplayId
 * @returns
 */
export const subtractDisplayId = (testWithDisplayId) => {
  const sampleDisplayId = (testWithDisplayId.includes('STA-') ? ' STA-' : ' ') + 'XYZ-1234'
  return testWithDisplayId.substr(0, Math.max(0, testWithDisplayId.length - sampleDisplayId.length)) // visual sample for length
}

/**
 * Converts an object of key value pairs to url query string.
 * Guaranteed to return string starting with ? and key/value pairs or empty.
 *
 * @param {Object} parameters
 * @returns
 */
export const buildQueryString = (parameters) => {
  // if (parameters && !isEmptyObject(parameters)) {
  //   return '?' + Object.entries(parameters)
  //     .filter(([, value]) => value !== undefined)
  //     .map(([key]) => key + '=' + parameters[key]).join('&')
  // } else return String()
  if (parameters && !isEmptyObject(parameters)) {
    const returnQueryString = new URLSearchParams()
    for (const [key, value] of Object.entries(parameters)) {
      if (value && (typeof value !== 'object')) {
        returnQueryString.append(key, value)
      }
    }
    const returnString = returnQueryString.toString()
    if (returnString) {
      return '?' + returnString
    } else {
      return String()
    }
  } else return String()
}

/**
 * Used to add / modify any query parameters without reloading.
 *
 * @param {*} parameters
 * @returns { urlParams, urlParamsBefore } Objects of both before and after query parameters
 */
export const getSetUrlParams = (parameters) => {
  if (clientWindow) {
    const queryString = clientWindow.location.search
    // consoleDev(thisFile, `getSetUrlParams queryString:`, queryString)
    const existingURLParameters = new URLSearchParams(queryString)
    const urlParamsBefore = {}
    for (const [key, value] of existingURLParameters) urlParamsBefore[key] = value
    // consoleDev(thisFile, `getSetUrlParams urlParamsBefore:`, urlParamsBefore)
    const urlParams = Object.assign({}, urlParamsBefore, parameters)
    // consoleDev(thisFile, `getSetUrlParams urlParams:`, urlParams)
    if (!isEmptyObject(urlParams)) {
      const newQueryString = buildQueryString(urlParams)
      if (clientWindow.history.pushState) {
        const newURL = new URL(window.location.href)
        newURL.search = newQueryString
        clientWindow.history.pushState({ path: newURL.href }, '', newURL.href)
      }
    }
    return { urlParams, urlParamsBefore }
  }
}

/**
 * Will change the name of a given field using the supplied callback function.  This is expecting JSON
 * but if you give it a cursor with the _doc prop, it will use that.
 *
 * @param {*} searchObject
 * @param {*} fieldName
 * @param {*} callback
 */
const invalidLeadCharacters = ['$', '_']
let highestLevel = 0
export const changeFieldName = (searchObject, fieldName, callback, level) => {
  if (level && (level > highestLevel)) {
    // consoleDev(thisFile, `level (${level} && level > highestLevel(${highestLevel})`)
    highestLevel = level
  }
  if (!level) {
    // consoleDev(thisFile, `!level level:`, level)
    highestLevel = 0
    level = 0
  }
  if (!searchObject) {
    // consoleWarn(thisFile, 'changeFieldName !searchObject returning.  fieldName:', fieldName)
    return
  }
  if (!fieldName) {
    // consoleWarn(thisFile, 'changeFieldName !fieldName returning.  searchObject:', searchObject)
    return
  }
  if (typeof searchObject === 'object') {
    // consoleDev(thisFile, `L[${level}] changeFieldName IS object`)
    if (Array.isArray(searchObject)) {
      // consoleDev(thisFile, 'ARRAY')
      for (const element of searchObject) {
        changeFieldName(element, fieldName, callback, level + 1)
      }
    } else {
      // consoleDev(thisFile, 'NOT ARRAY keys:', Object.keys(searchObject))
      // let index = 0
      for (const [key, value] of Object.entries(searchObject)) {
        // consoleDev(thisFile, `[${index}] [${key}, ${jsonStr(value)}] of searchObject:`, searchObject)
        let newKey
        if (key === fieldName) {
          // consoleDev(thisFile, `i${index} key:${key} matched fieldName:${fieldName}`)
          newKey = callback(key)
          searchObject[newKey] = searchObject[key]
          delete searchObject[key]
        } // else { consoleDev(thisFile, `key:${key} DID NOT match fieldName:${fieldName}`) }
        // consoleDev(thisFile, `key:${key} value:`, value)
        // Mongoose can add circular props.  Check for first character exceptions
        if (invalidLeadCharacters.indexOf(key.substr(0, 1)) > -1) {
          // exceptions _doc is for apollo query cursors and $or is for query syntax
          if (key !== '_doc' && key !== '$or') {
            // consoleDev(thisFile, 'invalid character skip ', key)
            continue // let _doc through since it is from Apollo / Mongo / Mongoose and wasn't called with toJSON
          }
        }
        // Go one more level (use newKey if we changed it)
        if (hasOwnProperty.call(searchObject, newKey || key)) {
          // consoleDev(thisFile, `newKey(${newKey}) || key(${key}):`, newKey || key, ' value:', value)
          if (value) {
            // consoleDev(thisFile, `calling changeFieldName(${value}, ${fieldName}, ${callback}, ${level + 1})`)
            changeFieldName(value, fieldName, callback, level + 1)
          }
        } // else consoleDev(thisFile, 'skip ', key, ' because value:', searchObject[key])
        // ++index
      }
    }
  }
}

/**
 * Converts date to midnight (to the millisecond) and 0 offset
 *
 * @param {*} fromDateTimeZoneISOString
 * @returns
 */
export const dobDate = (fromDateTimeZoneISOString) => {
  const dobDate = new Date(fromDateTimeZoneISOString)
  // set the hours ahead of utc by 12 to account for our time difference
  dobDate.setHours(12, 0, 0, 0)
  return moment(dobDate).utc(true)
}

/**
 * creates a 24hour range minus 1 millisecond
 * start at 00:00:00.000 and the end at 23:59:59.999
 * @param {*} date
 * @returns {Object} {start,end}
 */
export const dobDateRange = (date = new Date()) => {
  const dobDateStart = moment(date).startOf('day').utc(true)
  const dobDateEnd = moment(date).endOf('day').utc(true)
  return { start: dobDateStart, end: dobDateEnd }
}

/**
 * creates a 1 year range minus 1 millisecond
 * start at 00:00:00.000 and the end at 23:59:59.999
 * @param {String} age
 * @returns {Object} {start,end}
 */
export const getDobRangeFromAge = (age) => {
  age = parseInt(age)
  const start = moment().subtract(age, 'years').subtract(364, 'days').startOf('day').utc(true)
  const end = moment().subtract(age, 'years').endOf('day').utc(true)
  return { start, end }
}

/**
 * converts all keys of an object to lower case (i.e. for more tolerance of variation in API POST JSON bodies)
 * @param {Object} object
 */
export const lowerCaseAllKeys = (object) => {
  const keys = Object.keys(object)
  let n = keys.length
  const lowerCasedKeysObject = {}
  while (n--) {
    const key = keys[n]
    lowerCasedKeysObject[key.toLowerCase()] = object[key]
  }
  return lowerCasedKeysObject
}

/**
 * trims all keys (two levels deep, so including key.key1, key.key2) of an object of their whitespace (both ends)
 * @param {Object} object
 */
export const trimAllKeys = (object) => {
  const keys = Object.keys(object)
  let n = keys.length
  const trimmedKeysObject = {}
  while (n--) {
    const key = keys[n]
    const trimmedKey = key.trim()
    if (_.isObject(object[key]) && !_.isArray(object[key]) && !_.isEmpty(object[key])) { // this key's value is an object with keys that we also want to trim
      const secondTierKeys = Object.keys(object[key])
      let o = secondTierKeys.length
      trimmedKeysObject[trimmedKey] = {}
      while (o--) {
        const secondTierKey = secondTierKeys[o]
        const trimmedSecondTierKey = secondTierKey.trim()
        trimmedKeysObject[trimmedKey][trimmedSecondTierKey] = object[key][secondTierKey]
      }
    } else { // key's value is not an object
      trimmedKeysObject[trimmedKey] = object[key]
    }
  }
  return trimmedKeysObject
}

/**
 * turns an object into a human-readable sentence string, like "Client entered 'foo' for the 'bar' field, 'taco' for the 'bell' field."
 * will return null if the object is empty
 * @param {Object} object
 * @param {String} who
 */
export const objectToSentenceString = (object, subject, verb) => {
  const keys = Object.keys(object)
  const len = Object.keys(object).length
  if (!len) return null
  if (len) {
    let sentenceString = subject + ` ${verb} `
    if ((len === 1)) return (sentenceString += `'${object[0]}' for the '${keys[0]}' field.`)
    keys.forEach((key, index) => {
      if (index === 0) {
        sentenceString += `'${object[key]}' for the '${key}' field`
      }
      if (index > 0 && index < keys.length - 1) {
        sentenceString += `, '${object[key]}' for the '${key}' field`
      }
      if (index === keys.length - 1) {
        sentenceString += `, and '${object[key]}' for the '${key}' field.`
      }
    })
    return sentenceString
  }
}

/**
 * Used to shortcut &&'ing long variable chains.  Will return undefined if any element doesn't exist.
 *
 * @param {*} startRef
 * @param {*} chainString
 * @returns
 */
export const chainVar = (startRef, chainString) => {
  if (!startRef) return undefined
  if (!chainString) return undefined
  if (typeof chainString !== 'string') return undefined

  const propArray = chainString.split('.')
  let reference = startRef
  for (const prop of propArray) {
    if (!reference[prop]) return undefined
    reference = reference[prop]
  }

  return reference
}
/**
 * Replaces illegal characters with allowed equivalent
 *
 * @param {*} nameToBuild
 * @returns
 */
export const classNameBuilder = (nameToBuild) => {
  // consoleDev(thisFile, 'classNameBuilder nameToBuild:', nameToBuild)
  nameToBuild = nameToBuild.replace(/@/, '-AT-')
  nameToBuild = nameToBuild.replace(/\./, '-DOT-')
  // consoleDev(thisFile, 'classNameBuilder RETURNING nameToBuild:', nameToBuild)
  return nameToBuild
}
/**
 * Capitalized the first letter of a string regardless of the rest.
 *
 * @param {*} s
 * @returns
 */
export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 * NOTE: This does not work consistently, should use lodash's isEqual instead
 */
export function shallowEqual (objA, objB) {
  if (objA === objB) {
    return true
  }

  if (typeof objA !== 'object' || objA === null ||
      typeof objB !== 'object' || objB === null) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) {
    // consoleDev(thisFile, 'shallowEqual !length')
    return false
  }

  // Test for A's keys different from B.
  const bHasOwnProperty = hasOwnProperty.bind(objB)
  for (let i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      // consoleDev(thisFile, 'shallowEqual objA[keysA[i]] !== objB[keysA[i] objA[keysA[i]:', objA[keysA[i]],
      //   ' \nobjB[keysA[i]]:', objB[keysA[i]])
      // consoleDev(thisFile, 'shallowEqual !bHasOwnProperty')
      return false
    }
  }

  return true
}

/**
 *  Only compares top level
 *
 * @export
 * @param {*} instance
 * @param {*} nextProps
 * @param {*} nextState
 * @returns
 */
export function shallowCompare (instance, nextProps, nextState) {
  return (
    !shallowEqual(instance.props, nextProps) ||
    !shallowEqual(instance.state, nextState)
  )
}

/**
 * determines if an opp is a test opp base on whether it is scrambled,
 * the email contains @rdevs.com, or the email contains @caseopp.com
 * @param {*} opp
 * @returns {Boolean} true if any of the above are true, false otherwise
 */
export const isTestOpp = (opp) => {
  // consoleInfo(thisFile, 'isTestOpp opp.email:', opp.email)
  if (opp.scrambled) return true
  if (opp.email.includes('@rdevs.com')) return true
  if (opp.email.includes('@caseopp.com')) return true
  return false
}

/**
 * takes a camelCase and returns Camel Case
 * thanks https://stackoverflow.com/questions/4149276/how-to-convert-camelcase-to-camel-case
 * @param {String} camelCaseString
 */
export const deCamelCase = (camelCaseString) => {
  return camelCaseString
    .replace(/([A-Z])/g, ' $1') // insert a space before all caps
    .replace(/^./, (str) => str.toUpperCase()) // uppercase the first character
}

/**
 * deletes all (5 properties deep) occurrences of a key with given keyName from an object
 * down to object.key.subKey1.subKey2.subKey3.subKey4.fieldName
 * @param {Object} object
 * @param {String} keyName
 * @returns {Object} object with keyName occurrences removed
 */
export const deleteKey = (object, keyName) => {
  delete object[keyName] // delete __typename if it's there
  for (const key of Object.keys(object)) { // for every other key of object
    if (object[key] && Object.keys(object[key]).length) { // if that key has keys
      delete object[key][keyName] // remove any occurrence of __typename from it
      for (const subKey1 of Object.keys(object[key])) {
        if (object[key][subKey1] && Object.keys(object[key][subKey1]).length) {
          delete object[key][subKey1][keyName]
          for (const subKey2 of Object.keys(object[key][subKey1])) {
            if (object[key][subKey1][subKey2] && Object.keys(object[key][subKey1][subKey2]).length) {
              delete object[key][subKey1][subKey2][keyName]
              for (const subKey3 of Object.keys(object[key][subKey1][subKey2])) {
                if (object[key][subKey1][subKey2][subKey3] && Object.keys(object[key][subKey1][subKey2][subKey3]).length) {
                  delete object[key][subKey1][subKey2][subKey3][keyName]
                  for (const subKey4 of Object.keys(object[key][subKey1][subKey2][subKey3])) {
                    if (object[key][subKey1][subKey2][subKey3][subKey4] && Object.keys(object[key][subKey1][subKey2][subKey3][subKey4]).length) {
                      delete object[key][subKey1][subKey2][subKey3][subKey4][keyName]
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return object
}

/**
 * deletes multiple keys from an object
 * @param {Object} object
 * @param {Array} keyNames
 */
export const deleteKeys = (object, keyNames) => {
  for (const keyName of keyNames) {
    object = deleteKey(object, keyName)
  }
  return object
}

/**
 * accepts any type of input
 * if input is array of objects, deletes any occurrence of __typename from those objects
 * if input is an array with an array of objects in it, it scrubs each object (one array deep)
 * if input is object, deletes any occurrence of __typename from it (5 properties deep)
 * @param {*} input
 * @returns input without __typename
 */
export const scrubTypename = (input) => {
  if (!input) return undefined // called without input, return undefined
  const clonedInput = cloneDeep(input) // clone whatever we got
  if (Array.isArray(clonedInput)) { // if it's an array
    if (!clonedInput.length) return clonedInput // if empty array just return it
    else {
      return clonedInput.map(element => {
        if (Array.isArray(element)) { // if an array element is itself an array, scrub that too
          element = element.map(subEl => {
            if (Array.isArray(subEl)) { // if an element of the array contains an array, scrub any objects in it
              subEl = subEl.map(subEl2 => {
                if (typeof subEl2 === 'object') {
                  subEl2 = deleteKey(subEl2, '__typename')
                }
                return subEl2
              })
            } else if (typeof subEl === 'object') { // else if the element is an object, scrub it
              subEl = deleteKey(subEl, '__typename')
            }
            return subEl
          })
        } else if (typeof element === 'object') { // if array element is an object
          element = deleteKey(element, '__typename') // remove __typename if it's there
        }
        return element // return the element to the return array regardless of its type or if it had __typename
      })
    }
  } else if (typeof clonedInput === 'object') { // if it's an object
    if (_.isEmpty(clonedInput)) return clonedInput // return the cloned object even if empty
    return deleteKey(clonedInput, '__typename')
  } else {
    console.error('helper.scrubTypename can only process objects and arrays of objects but received ' + input)
    return clonedInput // whatever we got wasn't an array or object
  }
}

/**
 * moment-less formatting from raw timestamp or string of raw timestamp, to string
 * @param {Date or String} timestamp
 * @returns
 */
export const timestampToLocaleString = (timestamp, dateOnly) => { // eslint-disable-line no-unused-vars
  if (typeof timestamp === 'number') { // as with a raw timestamp like 1951-03-24T00:00:00.000+00:00
    //                                                                 DB - timestamp- 2021-05-05T17:13:31.464+00:00
    //                                                                 1648480576663 - current timestamp from cl of timestamp
    return new Date(timestamp).toLocaleString('en-US')
  } else if (typeof timestamp !== 'string') {
    timestamp = String(timestamp)
  }

  return new Date(timestamp).toLocaleString('en-US')
}

/**
 * Returns the difference between 2 objects. Handy for comparing why lodash isEqual is returning false
 * @param obj1
 * @param obj2
 *
 * @returns diff - difference between obj1 and obj2
 */
export const getObjectDiff = (obj1, obj2) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(obj2))
  return diff
}

/**
 * Returns current time given a timezone and state
 * @param {String} timeZone - Timezone as a String
 * @param {String} oppState - Needed to check if daylight savings is in effect
 * @param {Boolean} asDateString - Boolean to return full date string
 * @param {DateTime} selectedDateTime - DateTime to change to current timezone
 * @returns {String} time - current time in location
 */
export const getCurrentTime = (timeZone, oppState, asDateString = false, selectedDateTime) => {
  try {
  // DST === daylight savings time
    let isDST = false
    const stateIgnoresDST = ignoreDST.includes(oppState)// get states that do not observe DST
    if (!stateIgnoresDST) {
      const janDate = new Date(2000, 0, 1) // months are zero based, year is arbitrary, use jan since DST is march - nov
      const janTimeZoneOffset = janDate.getTimezoneOffset() // difference in minutes between UTC and local
      const curDate = new Date()
      const curDateTimeZoneOffset = curDate.getTimezoneOffset() // difference in minutes between UTC and local
      isDST = (janTimeZoneOffset !== curDateTimeZoneOffset) // detect DST observation
    }
    const currentTimeZone = timeZones.find(tZone => tZone.name === timeZone || tZone.std === timeZone || '')
    if (!currentTimeZone) return
    const timeZoneString = isDST ? currentTimeZone.dst : currentTimeZone.std
    if (selectedDateTime) {
      return new Date(selectedDateTime).toLocaleString('en-US', { timeZone: timeZoneString })
    }
    let currentTime = new Date().toLocaleString('en-US', { timeZone: timeZoneString })
    if (asDateString) {
      return currentTime
    }
    currentTime = new Date().toLocaleTimeString('en-US', { timeZone: timeZoneString, timeStyle: 'short' })
    return currentTime
  } catch (error) {
    consoleError(thisFile, 'timezone error: ', error.message)
    return ''
  }
}

/**
 * Gets the timezone based on the area code
 * @param {String} phoneToCheck
 * @returns {Object} timezone
 */
export const getTimezoneFromPhone = async (phoneToCheck) => {
  const results = {}
  const areaCode = phoneToCheck.substring(0, 3)
  const selectedTimeZone = phoneAreaCodes.find(timeZone => timeZone?.[areaCode])?.[areaCode]
  results.timeZone = timeZones.find(tz => {
    let timezone = tz.std
    if (timezone === 'America/Anchorage') timezone = 'AKST'
    return !!(timezone === selectedTimeZone)
  })?.name || ''
  return results
}

/** Stolen from https://stackoverflow.com/questions/23013573/swap-key-with-value-json
 *
 * @param {*} obj
 * @returns
 */
export const objectReverse = (obj) => {
  return Object.entries(obj).reduce((ret, entry) => {
    const [key, value] = entry
    ret[value] = key
    return ret
  }, {})
}

/**
 * looks for the two-letter abbreviation for a state name
 * @param {String} stateName
 * @returns {String} the state's two-letter abbreviation, or the original string
 */
export const stateNameToAbbreviation = (stateName) => {
  const foundState = states.find(state => state.name === stateName)
  if (foundState) {
    return foundState.abbreviation
  } else {
    return stateName
  }
}

/**
 * Posts the given payload to the specified endpoint
 * @param {String} endpoint
 * @param {Object} payload
 * @returns
 */
export const postToInternalApi = async (endpoint, payload) => {
  try {
    const postEndpoint = new URL(endpoint, baseUrl)
    await fetch(postEndpoint.toString(), {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    return
  } catch (error) {
    consoleError(thisFile, 'postToInternalAPI error: ', error.message)
  }
}

/**
 * converts a boolean to Yes or No
 * @param {Boolean} bool
 * @returns {String} Yes or No
 */
export const boolToYN = (bool) => {
  if (bool && bool !== 'false') {
    return 'Y'
  } else {
    return 'N'
  }
}

/**
 * Returns Version
 *
 * @returns {string}
 */
export const getVersion = () => {
  if (variantToBool(process.env.STATIC_VERSION)) {
    return 'YYYY.MM.DD'
  }
  return appVersion
}

/**
 * Adds Review Flag to RI Admin Portal
 * @param {object} reviewFlag review flag object must include attributes: opportunityId, oppDisplayId, reason, description, agent, createdBy
 */
export const addReviewFlag = async (reviewFlag) => {
  try {
    const reviewFlagsURL = new URL('api/reviewFlags/addReviewFlag', adminPortalBaseUrl)
    // eslint-disable-next-line no-undef
    await fetch(reviewFlagsURL.toString(), {
      method: 'POST',
      headers: { apitoken: process.env.RI_ADMIN_PORTAL_AUTH_TOKEN },
      body: JSON.stringify(reviewFlag)
    })
  } catch (error) {
    throw new Error('addReviewFlagError error: ' + error.message)
  }
}

/**
 * Checks if the given question meets the conditional requirements based on answers to other questions
 * @param {object} question question object
 * @param {array} answers array of answer objects
 * @returns {boolean} true if there are not conditionals, or if the conditionals are met
 */
export const conditionalQuestionChecker = (question, answers) => {
  try {
    if (!question?.conditionals?.length) { // Question has no conditionals, question is visible
      return true
    }
    const questionDelimiter = ' | '
    const questionType = question.answerType
    let conditionalsMet = true // assume conditionals are met, change to false if any conditionals are not met
    question.conditionals.forEach((conditional) => {
      const answerValue = getJsonWithErrorHandling(conditional.answerValue)
      const answerToCheck = answers?.find(answer => answer?.question === conditional.questionId) // Find the answer to the conditional question
      switch (conditional.operator) {
        case 'contains':
          // Only used for selects
          // answerValue will be string
          if (!answerToCheck?.answer?.split(questionDelimiter)?.includes(answerValue)) {
            conditionalsMet = false
          }
          break
        case 'doesNotContain':
          // Only used for selects
          // answerValue will be string
          if (answerToCheck?.answer?.split(questionDelimiter)?.includes(answerValue)) {
            conditionalsMet = false
          }
          break
        case 'isAnyOf':
          // Only used for selects
          // answerValue will be array of strings
          if (!arraysShareCommonElements(answerToCheck?.answer?.split(questionDelimiter), answerValue)) {
            conditionalsMet = false
          }
          break
        case 'isNotAnyOf':
          // Only used for selects
          // answerValue will be array of strings
          if (arraysShareCommonElements(answerToCheck?.answer?.split(questionDelimiter), answerValue)) {
            conditionalsMet = false
          }
          break
        case 'is':
          // answerValue will be string
          if (questionType === 'Select Many') { // If questionType is selectMany, answerToCheck.answer will be a string that needs to be split to an array.
            if (!answerToCheck?.answer?.split(questionDelimiter)?.toString() === [answerValue].toString()) {
              conditionalsMet = false
            }
          } else if (answerToCheck?.answer !== answerValue) {
            conditionalsMet = false
          }
          break
        case 'isNot':
          // answerValue will be string
          if (questionType === 'Select Many') { // If questionType is selectMany, answerToCheck.answer will be a string that needs to be split to an array.
            if (answerToCheck?.answer?.split(questionDelimiter)?.toString() === [answerValue].toString()) {
              conditionalsMet = false
            }
          } else if (answerToCheck?.answer === answerValue) {
            conditionalsMet = false
          }
          break
        case 'isEmpty':
          if (!answerToCheck?.answer || answerToCheck?.answer !== '') {
            conditionalsMet = false
          }
          break
        case 'isNotEmpty':
          if (answerToCheck?.answer && answerToCheck?.answer === '') {
            conditionalsMet = false
          }
          break
        case 'greaterThan':
          if (answerToCheck?.answer && Number(answerToCheck?.answer) < Number(answerValue)) {
            conditionalsMet = false
          }
          break
        case 'lessThan':
          if (answerToCheck?.answer && Number(answerToCheck?.answer) > Number(answerValue)) {
            conditionalsMet = false
          }
          break
        case 'greaterThanOrEqualTo':
          if (answerToCheck?.answer && Number(answerToCheck?.answer) <= Number(answerValue)) {
            conditionalsMet = false
          }
          break
        case 'lessThanOrEqualTo':
          if (answerToCheck?.answer && Number(answerToCheck?.answer) >= Number(answerValue)) {
            conditionalsMet = false
          }
          break
        case 'isBefore':
          if (isDateAfter(answerToCheck?.answer, answerValue)) {
            conditionalsMet = false
          }
          break
        case 'isAfter':
          if (isDateAfter(answerValue, answerToCheck?.answer)) {
            conditionalsMet = false
          }
          break
        case 'isOlderThan':
          if (!isDateOlderThan(answerValue[0], answerValue[1], answerToCheck?.answer)) {
            conditionalsMet = false
          }
          break
        case 'isYoungerThan':
          if (isDateOlderThan(answerValue[1], answerValue[0], answerToCheck?.answer)) {
            conditionalsMet = false
          }
          break
        default:
          consoleError(thisFile, 'conditionalQuestionChecker error: ', 'operator not found')
          break
      }
    })
    return conditionalsMet
  } catch (error) {
    consoleError(thisFile, 'conditionalQuestionChecker error: ', error.message)
    return true // If error, assume conditionals are met and return true
  }
}

/**
 * Finds if arrays share any common elements
 * @param {array} array1
 * @param {array} array2
 * @returns {boolean} true if there are common elements
 */
export const arraysShareCommonElements = (array1, array2) => {
  if (!array1 || !array2) {
    return false
  }
  return array1.some(element => array2.includes(element))
}

/**
 * Checks if one date is after another date
 * @param {string} date1
 * @param {string} date2
 * @returns {boolean} true if date1 is after date2
 */
export const isDateAfter = (date1, date2) => {
  const date1Date = new Date(date1)
  const date2Date = new Date(date2)
  return date1Date > date2Date
}

/**
 * Checks if date is older than amount of time
 * @param {number} amountOfUnits number of days, weeks, months, or years
 * @param {string} unit 'days', 'weeks', 'months', or 'years'
 * @param {string} date date to check
 * @returns {boolean} true if date is older than amountOfUnits
 */
export const isDateOlderThan = (amountOfUnits, unit, date) => {
  const dateDate = new Date(date)
  const currentDate = new Date()
  const timeDiff = Math.abs(currentDate.getTime() - dateDate.getTime())
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
  switch (unit) {
    case 'days':
      return diffDays > amountOfUnits
    case 'weeks':
      return diffDays > amountOfUnits * 7
    case 'months':
      return diffDays > amountOfUnits * 30
    case 'years':
      return diffDays > amountOfUnits * 365
    default:
      consoleError(thisFile, 'isDateOlderThan error: ', 'unit not found')
      return false
  }
}

/**
 * convert queryFilters to mongoDB filters
 * @param {String} operator
 * @param {Any} value
 * @returns filter value
 */
export const getMongoFilter = (operator, value) => {
  let filterValue = {}
  switch (operator) {
    case 'isAnyOf':
      filterValue = { $in: value }
      break
    case 'isNotAnyOf':
      filterValue = { $nin: value }
      break
    case 'equals':// strict equality
    case '=':
      filterValue = { $eq: value }
      break
    case 'not':
    case '!=':
      filterValue = { $ne: value }
      break
    case 'isEmpty':
      filterValue = { $eq: '' }
      break
    case 'isNotEmpty':
      filterValue = { $ne: '' }
      break
    case 'after':
    case '>':
      filterValue = { $gt: value }
      break
    case 'onOrAfter':
    case '>=':
      filterValue = { $gte: value }
      break
    case 'before':
    case '<':
      filterValue = { $lt: value }
      break
    case 'onOrBefore':
    case '<=':
      filterValue = { $lte: value }
      break
    case 'is':
    default:
      filterValue = value
      break
  }
  return filterValue
}

/**
 * parses values based on how they are stored in the database
 * @param {String} field
 * @param {Any} value
 * @returns formatted value
 */
export const mongoValueFormatter = (field, value) => {
  let formattedValue = value
  switch (field) {
    case '_id':
    case 'campaignId':
    case 'flagIds':
      if (Array.isArray(value)) {
        formattedValue = value.map(singleValue => new ObjectId(singleValue))
        break
      }
      formattedValue = new ObjectId(value)
      break
    case 'createdAt':
    case 'updatedAt':
    case 'lastContacted':
    case 'lastAgentUpdated':
      formattedValue = new Date(value)
      break
    case 'contactAttemptsCount':
    case 'priority':
    case 'followUp':
      formattedValue = parseInt(value, 10)
      break
    case 'phone':
    case 'alternatePhone':
      formattedValue = formatPhoneNumber(value)
      break
    case 'comments':
    case 'clientComments':
    case 'caseComments':
      formattedValue = value
      break
    default:
      break
  }
  return formattedValue
}

/**
 * Returns an object with a qualified property that is true if the opportunity is qualified, and a
 * missingQualifications property that is an array of objects with a section property and a missing
 * property that is an array of strings.
 * @param {Object} opportunity - the opportunity object
 * @param {Object} campaign - the campaign object
 * @param {Boolean} qualifyingQuestionsMet - true if all qualifying questions are met, false if not
 * @param {Array} accessRules - an array of strings that represent the access rules that the user has
 * @param {Object} user - the user object
 * @returns {Object} An object with two properties: qualified and missingQualifications.
 */
export const oppIsQualified = (opportunity, campaign, qualifyingQuestionsMet, accessRules, user) => {
  const tempMissingQualifications = []
  // clientInfoQualified is true when firstName, lastName, dob, and either email or password are populated
  let clientInfoQualified = true
  const missingClientInfo = { section: 'Client Info', missing: [] }
  if (!opportunity?.firstName) missingClientInfo.missing.push('First Name')
  if (!opportunity?.lastName) missingClientInfo.missing.push('Last Name')
  if (!opportunity?.email && !opportunity?.phone) missingClientInfo.missing.push('Email or Phone')
  if (!opportunity?.dob) missingClientInfo.missing.push('DOB')
  // if missingClientInfo.missing.length > 0, add it to tempMissingQualifications and set clientInfoQualified to false
  if (missingClientInfo.missing.length) {
    tempMissingQualifications.push(missingClientInfo)
    clientInfoQualified = false
  }
  // addressQualified is true when addressStreet, addressCity, addressState, and addressZip are populated
  let addressQualified = true
  const missingAddress = { section: 'Address', missing: [] }
  if (!opportunity?.addressStreet) missingAddress.missing.push('Street')
  if (!opportunity?.addressCity) missingAddress.missing.push('City')
  if (!opportunity?.addressState) missingAddress.missing.push('State')
  if (!opportunity?.addressZIP) missingAddress.missing.push('Zip')
  // if missingAddress.missing.length > 0, add it to tempMissingQualifications and set addressQualified to false
  if (missingAddress.missing.length) {
    tempMissingQualifications.push(missingAddress)
    addressQualified = false
  }
  // campaignNotInactive is true when campaign.status is not 'Inactive'
  let campaignNotInactive = true
  if (campaign.status === 'inactive') {
    tempMissingQualifications.push({ section: 'Campaign Inactive', missing: [] })
    campaignNotInactive = false
  }
  // injuredPartyInfo will be true if opportunity.injuredPartyDifferent is not true,
  // or if opportunity.injuredPartyDifferent is true, and opportunity.injuredPartyFirstName,
  // opportunity.injuredPartyLastName, opportunity.injuredPartyDOB, and opportunity.injuredPartyRelation are populated
  let injuredPartyInfo = true
  if (opportunity?.injuredPartyDifferent) {
    const missingInjuredPartyInfo = { section: 'Injured Party Info', missing: [] }
    if (!opportunity?.injuredPartyFirstName) missingInjuredPartyInfo.missing.push('First Name')
    if (!opportunity?.injuredPartyLastName) missingInjuredPartyInfo.missing.push('Last Name')
    if (!opportunity?.injuredPartyDOB) missingInjuredPartyInfo.missing.push('DOB')
    if (!opportunity?.injuredPartyRelation) missingInjuredPartyInfo.missing.push('Relation')
    if (missingInjuredPartyInfo.missing.length) {
      tempMissingQualifications.push(missingInjuredPartyInfo)
      injuredPartyInfo = false
    }
  }
  // smartyStreetsVerified is true when opportunity.smartyStreetsVerified or opportunity.addressManuallyVerified is true
  let smartyStreetsVerified = true
  if (!opportunity?.smartyStreetsVerified && !opportunity?.addressManuallyVerified) {
    tempMissingQualifications.push({ section: 'Address not verified' })
    smartyStreetsVerified = false
  }
  if (!qualifyingQuestionsMet) {
    tempMissingQualifications.push({ section: 'Qualifying Questions conditions not met' })
  }

  if (clientInfoQualified && addressQualified && campaignNotInactive && injuredPartyInfo && smartyStreetsVerified && qualifyingQuestionsMet) {
    // opportunityIsQualified is true when all of the above are true
    return {
      qualified: true,
      missingQualifications: []
    }
  } else if (hasAccess('action:sendDocsOnInactive', accessRules, user) && clientInfoQualified && addressQualified && injuredPartyInfo && smartyStreetsVerified && qualifyingQuestionsMet) {
    // opportunityIsQualified is true when user has access to send docs on inactive campaigns and all of the above are true except for campaignNotInactive
    return {
      qualified: true,
      missingQualifications: []
    }
  }
  return {
    qualified: false,
    missingQualifications: tempMissingQualifications
  }
}

/**
 * Return a new object with all the keys of the original object except for the ones in the exclude
 * array.
 * @param obj - The object to filter
 * @param exclude - an array of keys to exclude from the object
 * @returns A new object with the keys that are not in the exclude array.
 */
export function filterObject (obj, exclude) {
  return Object.keys(obj)
    .filter(key => !exclude.includes(key))
    .reduce((newObj, key) => {
      newObj[key] = obj[key]
      return newObj
    }, {})
}

/**
 * It takes a campaignId and a delta object, and then it makes a request to the server to check if any
 * event actions should be triggered
 * @param {String} opportunityId - the opportunity id
 * @param {String} campaignId - the campaign id
 * @param {Object} delta - the delta object
 */
export const checkEventActions = async (opportunityId, campaignId, delta = { status: 'test' }) => {
  const eventActionsResponse = await fetch(`/api/rest/eventActionCheck?opportunityId=${opportunityId}&campaignId=${campaignId}&delta=${JSON.stringify(delta)}`)
  const eventActions = await eventActionsResponse.json()
  if (eventActions.triggered) {
    return eventActions.eventActions
  }
  return false
}

/**
 * Gets the url to use for api calls. It checks to see if the status is up before returning the baseApiUrl, if it is not up, it returns the baseUrl.
 *
 * @returns {String} The url to use for api calls.
 */
export const getApiUrl = async () => {
  try {
    const status = await fetch(baseApiUrl + '/status', { timeout: 2000 })
    if (status.ok) {
      return baseApiUrl
    } else {
      return baseUrl + '/api'
    }
  } catch {
    return baseUrl + '/api'
  }
}

/**
 * Takes an interval and a range, and returns a date that is the interval ago from the current date
 * @param interval - The number of minutes, hours, days, weeks, months, or years to go back.
 * @param range - The range of time you want to get.
 * @returns A date object
 */
export const getTimeAgo = (interval, range) => {
  const timeAgo = new Date()
  switch (range) {
    case 'Minutes':
      return timeAgo.setMinutes(timeAgo.getMinutes() - interval)
    case 'Hours':
      return timeAgo.setHours(timeAgo.getHours() - interval)
    case 'Days':
      return timeAgo.setDate(timeAgo.getDate() - interval)
    case 'Weeks':
      return timeAgo.setDate(timeAgo.getDate() - (interval * 7))
    case 'Months':
      return timeAgo.setMonth(timeAgo.getMonth() - interval)
    case 'Years':
      return timeAgo.setFullYear(timeAgo.getFullYear() - interval)
    default:
      return timeAgo
  }
}

/**
 * Returns Release Notes
 *
 * @returns {string}
 */
export const getReleaseNotes = () => {
  return [
    {
      version: '2020.01.06-1',
      bugFixes: ['Fixed Manager search bug.'],
      newFeatures: [
        'Added documentation link.',
        'Added Feature / Bug report page.'
      ]
    },
    {
      version: '2020.01.06-2',
      newFeatures: ['Added these release notes.']
    },
    {
      version: '2020.01.07-0',
      newFeatures: ['Added questionSlug to project to facilitate moving q/a between campaigns']
    },
    {
      version: '2020.01.08-0',
      newFeatures: [
        'Changed selected opp assignment from user to intake user.',
        'Sorted that user list.'
      ]
    },
    {
      version: '2020.01.08-1',
      newFeatures: ['Added question slugs']
    },
    {
      version: '2020.01.09-0',
      bugFixes: ['Fix Campaign Sort Hanging RDW-435']
    },
    {
      version: '2020.01.14-4',
      bugFixes: ['Fix auto assigning of users (intake, manager, etc.).'],
      newFeatures: [
        'Add sorting to all dropdowns and user options on Intake Assign.',
        'Change verbiage static AMI endpoint'
      ]
    },
    {
      version: '2020.03.19',
      bugFixes: ['Fix campaign question order bug from legacy merge code'],
      newFeatures: [
        'Set to use auth0 on AMI endpoint',
        'Change default opportunity csv download to include userCampaigns'
      ]
    },
    {
      version: '2020.03.20',
      newFeatures: [
        'Add sorting to the rest of the app',
        'Multi select for user dropdowns with tooltip on side instead of top'
      ]
    },
    {
      version: '2020.03.23',
      bugFixes: ['Fix file upload bug (573) with resolutions in package.json (fs-capacitor and graphql-upload)'],
      newFeatures: [
        'Add JSON to webhook header',
        'Refactor question template button'
      ]
    },
    {
      version: '2020.03.24',
      bugFixes: ['Import bug fix and polish (campaign to campaignId)'],
      newFeatures: ['Add status slug requirement to settings']
    },
    {
      version: '2020.03.25',
      newFeatures: [
        'Expose ability to remove deleted AssureSign document templates from Campaigns',
        'Add AssureSign Documents to Campaign table'
      ]
    },
    {
      version: '2020.03.27',
      newFeatures: [
        'Add statistics page with sample widgets and working auth0 user meta update',
        'Opportunity Locking first version'
      ]
    },
    {
      version: '2020.03.30',
      newFeatures: ['Remove old campaigns query, helpers, and resolver (now using campaignSearch)']
    },
    {
      version: '2020.03.31',
      newFeatures: ['AssureSign documents in opportunities show current status and are fully manageable']
    },
    {
      version: '2020.04.01',
      newFeatures: ['Add basic queue statistics table to statistics page']
    },
    {
      version: '2020.04.03',
      newFeatures: [
        'Update Opp Locking and bulk select',
        'Update loading icon functionality for login / logout and page loads'
      ]
    },
    {
      version: '2020.04.08',
      bugFixes: [
        'Bug fix for /api/createOpp REST endpoint being used by some clients for integration that was causing a fail message to be returned and no opp to be created',
        'Fixed git capitalization issues on new components folder organization'
      ],
      newFeatures: [
        'Added lowerCaseKeys functionality to createOpp REST endpoint to catch possible client mistakes',
        'Removed AssureSign Doc Names from the campaigns grid as they were massively slowing down the loading for it and were unneeded.',
        'Readme updates for better developer instructions to run and test the app',
        'Style Tweaks and continued logic work in resolver for Status Stats Widget'
      ]
    },
    {
      version: '2020.04.14',
      bugFixes: [
        'Various bug fixes to stabilize the Assuresign Doc sending auto status change.',
        'Fixed History so it properly shows the user that made the change'
      ],
      newFeatures: [
        'Added bulk SMS sending to Opp Grid area',
        'Added initial framework for End to End testing suite with Jest/Puppeteer',
        'Updated various packages (including NextJS to 9.3.x)',
        'Updated Assuresign webhook endpoint to include Opp Display ID on error messages for easier debugging',
        'Updated Assuresign webhook endpoint to not report error if Opp was already in signedReady status',
        'Updated Assuresign API account so that it is using intake@caseopp.com instead of donnells account'
      ]
    },
    {
      version: '2020.04.20',
      newFeatures: [
        'Moved input labels above fields rather than in them on Question Tab of Opp FSD',
        'Added Notes field to core Opp Client Info',
        'Data migration to set a bunch of SJL dupes to dupe status'
      ]
    },
    {
      version: '2020.04.23',
      bugFixes: [
        'Fixed bulk intake user assignment so that when it is selected it updates the grid'
      ],
      newFeatures: [
        'Refactored where comments are stored (should have no end user effect)',
        'Added ability to search comments in Opps grid',
        'Changed Opp grid rows to not check on click anywhere on the row',
        'Replaced Google Places auto address search and replaced with Smarty Streets',
        'Added automatic Smarty Streets address verification',
        'Added lots more Unit and E2E tests for better QA'
      ]
    },
    {
      version: '2020.04.28',
      bugFixes: [
        'Fixed status not updating on assuresign send when opp stays open',
        'Fixed download CSV issue',
        'Fixed API Token Create issue'
      ],
      newFeatures: [
        'Changed Campaign and Question grid defaults to 50/page',
        'More E2E and Unit and Snapshot tests added for better QA'
      ]
    },
    {
      version: '2020.05.04',
      bugFixes: [
        'Fixed Opp Locking not getting unset when closing Opp FSD',
        'Fixed some formatting issues on the csv download for multiselect values and some dates',
        'Fixed some errors that were showing in some Opps assuresign docs section',
        'Fixed issue where a new Auth0 token was being created every heartbeat'
      ],
      newFeatures: [
        'Added Support Area with functionality for admins to add/edit articles and for articles to smartly show on various pages throughout the app',
        'Added Priority field that you can search/sort by in Opps Grid that can be set on the Campaign level and applied to Opps in that campaign',
        'Added User Conversion Stats report for Admin use to track intake user performance',
        'Added Status Change Restrictions so that you cannot set an opp that has not been signed or reviewed to Qualified Sent',
        'Added Campaign Slug in parens in the campaign selector on Opps Grid for easier reference',
        'LOTS of tech debt cleanup (clear out of un-needed functions and packages) for faster deployment times',
        'LOTS more testing added for better QA'
      ]
    },
    {
      version: '2020.05.06',
      bugFixes: [
        'Bug fix for getOpp API that Atraxia uses',
        'Bug fix for Opp locking not releasing lock on close tab',
        'Bug fix to show Signed Assuresign doc when multiple sent docs exist'
      ],
      newFeatures: [
        'Added caching to lockedOpps heartbeat query to reduce database load',
        'Unit tests for Date Picker packages (date-io)',
        'Added URL Rewrite to include the opp ID for when you open an opp from the home page Opp table'
      ]
    },
    {
      version: '2020.05.19',
      bugFixes: [
        'Fixed Date Sent/Signed timestamps from getting overwritten if a status toggles back to the triggers for them; keeps the original timestamp',
        'Fixed issue with opp counts being off',
        'Fixed issue with heartbeat sometimes messing with the status',
        'Fixed issue with Auth0 M2M token not getting re-used till expiration properly'
      ],
      newFeatures: [
        'Replace most instances of getInitialProps with getServerSideProps',
        'Filters in Opp Grid now stick with session so you can navigate away and come back w/o losing your filters',
        'High Priority New Leads are now shown as count badge on the top right menu as a total for Admins and specific for intake agents',
        'Added Live Chat as a option for Opportunity Source',
        'Added ability to set a users Role in user management',
        'Added revamped Assuresign section to documents tab of Opportunity view for easier use',
        'Added support sidebar menu for easier navigation in main support area',
        'Added functionality to create a custom Opp Detail View per campaign for better deliverables to clients',
        'Added Cases area for use by our partner firms',
        'Added pagination for Support Articles search results',
        'Additional Unit and E2E tests added for better QA',
        'Tech debt updates for better login experience and smoother App loading',
        'Upgrade to faster version of core framework (Nextjs)!'
      ]
    },
    {
      version: '2020.05.22',
      bugFixes: [
        'Document Tab fixes',
        'Stats Fix for conversions report'
      ]
    },
    {
      version: '2020.05.26',
      bugFixes: ['Fixed documents on Opp Detail page to only show signed assuresign docs'],
      newFeatures: [
        'Added dynamic catchall route for prettier Opp/Case Detail URL',
        'Added ability to import comments',
        'Added ability to further templatize the Opp/Case Detail page per campaign with question/answer merge fields',
        'Created campaign opp detail templates for BOP, MOP, and KKZ',
        'Updated Campaign settings page to update on the fly instead of needing refresh',
        'Added pagination (infinite scroll effect) to Support area'
      ]
    },
    {
      version: '2020.05.26-2',
      newFeatures: ['Add logging page for admins and improve/verify its functionality']
    },
    {
      version: '2020.05.29',
      bugFixes: ['Fix Smarty Streets search and lookup'],
      newFeatures: [
        'Update all top level pages for new loading cancel for faster loads',
        'Add slug check to supporticle form',
        'Add consoleX logging',
        'Set log tool cursor with rowsPerPage'
      ]
    },
    {
      version: '2020.06.01',
      bugFixes: [
        'Fixed smarty street search box',
        'Fixed No Mail search in Opp Grid'
      ],
      newFeatures: [
        'Work to stabilize the loading graphic',
        'Added columns for PIN, Flag, AIS Q URL, and Client View to opp grid',
        'More work on questionnaire webhook processing',
        'Expanded testing for new cases area',
        'Added place holder for Litigation tab in Opp FSD',
        'Added createOpp API functionality to send in intake_qa',
        'Added Logs to UI for Admin/Dev use for easier debugging'
      ]
    },
    {
      version: '2020.06.07',
      bugFixes: ['Fix for bug in Client View (oppDetail) for multiselect type answers with only one selection.'],
      newFeatures: ['Update to database connector in hopeful fix for dropped connections causing various random issues']
    },
    {
      version: '2020.06.11',
      bugFixes: ['More stabilization and bug fixes for opp locking'],
      newFeatures: [
        'Added Title, Suffix, Preferred Name, Secondary Email, Phone Extension, and Phone Type Client Info fields',
        'Added Campaign Custom Fields management',
        'Added Campaign Conversion Stats report',
        'Made the question answer text fields able to better handle multi-line values',
        'Updated questionnaire to handle internal users entering data',
        'Flag field migrations to get it in order for the manual questionnaire answer push',
        'Initial adapter and graphql setup for Google Storage (for documents)',
        'Added Campaign Conversion Stats report',
        'Case Edit page (FSD) updates and polish including switch to vertical tabs'
      ]
    },
    {
      version: '2020.06.13',
      newFeatures: ['Added getUploadURL REST API']
    },
    {
      version: '2020.06.25',
      newFeatures: [
        'Initial official release of new Cases area targeted for partner firm users (but avail to all)',
        'Added validation messages to Name, Phone, and Email fields to notify of proper format (Note: Not required to fix for save)',
        'Datatable update for better experience on mobile for the Opps/Cases grids and other minor improvements',
        'Added Campaign grouping for easier filterability (setup AIS group for initial usage)',
        'Changes to API and Webhooks to include document download links for Velawcity integration',
        'Revamped document manual upload area to use better cloud storage and nicer interface for uploading multiple files',
        'Made it so you can unset a boolean answer',
        'Added functionality so you can now search for "exact phrases" in the column filters on the Opp grid by surrounding your search with double quotes.'
      ]
    },
    {
      version: '2020.07.02',
      bugFixes: [
        'Fixed Name validation issue not allowing for spaces in middle of names, i.e. Van Arsdale',
        'Fixed User Management bug that was causing page not to load',
        'Fixed Chatlio Widget issue on Firm Users Cases interface so it hides when Case Edit screen is open',
        'Fixed sorting not working on Cases table',
        'Fixed litigation tab in Opp so it doesnt error',
        'Fixed slack integration for Bug Reporting',
        'Fixed Opp Detail Client View page so assuresign opps work now',
        'Fix script for removing extra set of double quotes on answers'
      ],
      newFeatures: [
        'Many various fixes to help stabilize and resolve the loading/timeout errors!',
        'Added commenting system to Cases (same UI but stores separate from Opp comments)',
        'Added specific url capabilities to cases area so you can now see/send links that include the ID in the url for cases',
        'Added Case Status to Opp Grid',
        'Upgrades to "Reconciler" tool to help marry up data that has been imported with more recent data in spreadsheet format',
        'removed heartbeat for firm users'
      ]
    },
    {
      version: '2020.07.08',
      bugFixes: [
        'Fixed bug in Opp download report where it was including questions on multi-campaign filtered lists',
        'Fixed Smarty Streets validation bug in cases client info tab'
      ],
      newFeatures: [
        'Added Manual Q Entry link for blank flags',
        'More Velawcity integration tweaks',
        'Added dob to REST API getOpp function',
        'Added Flag to fields that you can do "Exact" searches on',
        'Added Campaign Group column to Opp Grid',
        'Added Delete Document functionality for manual uploads area',
        'Made Case Custom Fields (Client Questionnaire) fields dynamic sizing multi-line text boxes',
        'Removed Case Statuses from Opp Status dropdown',
        'Auth0 integration fix for when we break 100 users (added pagination to Auth0 management API queries)',
        'Added Age column to cases table',
        'Lots of Reconciler tool upgrades'
      ]
    },
    {
      version: '2020.07.15',
      bugFixes: [
        'Fixed Case Custom Fields (Client Questionnaire section) not saving',
        'Fixed Bug not allowing user to cancel out of Smarty Streets recommended address changes'
      ],
      newFeatures: [
        'Changed Litigation tab in Opp to Case that is a link to the corresponding Case',
        'Added Case POC Document Template Merge functionality (Formstack Documents Integration) with Preview buttons',
        'Added Case POC send for signing functionality (Eversign Integration) including webhooks to listen for sign complete events',
        'Added automatic document attachment to Opp/Case for AIS Cognito form questionnaire submit',
        'Code refactoring for better user notification of saving errors'
      ]
    },
    {
      version: '2020.07.22',
      bugFixes: [
        'Fixed various POC mapping issues',
        'Fixed comments bug in cases',
        'Fixed issue with selects/multi-selects in case custom fields not showing options for when value is blank'
      ],
      newFeatures: [
        'Added External ID to Opps table',
        'Updated Case Client Questionnaire fields for select and multi-select types',
        'Reworked "heartbeat" functionality that periodically checks for new priority opps, locked opps, and queue status for smoother operation',
        'Added user error and retry button box for when an auto-save doesnt work (previously didn\'t tell the user there was an error)',
        'Added Date Signed Column in cases table',
        'Velawcity integration updates',
        'Added Questionnaire Upload prefix to files uploaded to Cases/Opps from the cognito form questionnaire',
        'Reconciler tool upgrades to use Web Workers for more efficient processing of data',
        'Added debugging and additional logging messages to cognito form submission webhook handler to help track issues with it not always working',
        'Added highlighting of Questionnaire fields that are POC mapped'
      ]
    },
    {
      version: '2020.07.31',
      bugFixes: [
        'Fixed Cases grid so it uses fresh data in the Edit Specific Case view',
        'Fix for missing attachments',
        'Fixed bug with Cases Table filtering removing the non-default columns that were selected'
      ],
      newFeatures: [
        'Lots more POC mapping updates',
        'Added ability to upload larger file attachments',
        'Made filters persist on Cases Table',
        'Reconciler updates to handle phone lookup tasks',
        'Velawcity integration bug fixes',
        'Added high level AIS Stats report',
        'Cognito form submit will now only overwrite Client Questionnaire data if Case is in 1. Questionnaire Pending status'
      ]
    },
    {
      version: '2020.08.12',
      bugFixes: [
        'Fixed bug not allowing for Blank Out of a Client Questionnaire field in Cases',
        'Fixed issue with cases table reloading multiple times',
        'Fixed comments popover functionality on cases table',
        'Fixed Support Article editor',
        'Fixed chat that would open automatically for firm users upon returning to cases table',
        'Fixed issue with DOB on POC where if it was the 1st of the month bday then the previous month was being put on POC.'
      ],
      newFeatures: [
        'Added functionality to assign users to cases. Assignable users can be configured on a campaign basis.',
        'Added more automated testing coverage to the cases area',
        'Added better loading indicators on Cases table so it doesnt appear blank for a few seconds on initial load',
        'Added better security for users restricted to certain campaigns',
        'Setup Opportunities so that if they are in Qualified Sent status only an Admin user can change it.',
        'Update to POC for Zuckerman specific info for the 3 ZS campaigns.',
        'Various background upgrades to ensure case data stability.',
        'Updated staging scrambler to generate different phone numbers for each Opp'
      ]
    },
    {
      version: '2020.08.24',
      bugFixes: [
        'Fixed bug where Username was missing on User Conversion Report',
        'Fixed docs not showing in client view (hotfix last week)',
        'Fixed issue where documents were not getting attached from Eversign, Cognito, and Assuresign webhooks',
        'Various AIS POC Mapping issues (mostly applied as hotfixes last week)',
        'Fixed issue with Last Updated not getting set properly (hotfix last week)'
      ],
      newFeatures: [
        'Added new history functionality',
        'Added new campaign breakdown stats table to AIS Stats',
        'Revamped flag system to more easily add and search for flags',
        'Campaign Copy functionality added',
        'Enhanced Saving indicators and redundancies for Case Client Questionnaire',
        'Upgraded documents to allow larger uploads (applied in hotfix last week)',
        'Added functionality to download the POC for mailing along with a status for it (applied in hotfix last week)',
        'Disabled the Send POC for ESign button in cases if the Email is not filled in with a proper email address (hotfix last week)',
        'Added LCC as an option to Sources (hotfix last week)'
      ]
    },
    {
      version: '2020.09.14',
      bugFixes: [
        'Fixed preferred name validation bug',
        'Fixed some double page loading issues',
        'Fixed bug not allowing client questionnaire text fields to be blanked out',
        'Fixed bug in Opps table so now Case Status can be seen and filtered by correctly',
        'Fixed Campaign Copy functionality to also copy over custom fields and case assignable users, and to notify you when copy was completed',
        'Fixed bug not allowing # in email validation',
        'Fixed bug causing Flags to not get correctly set when a cognito form questionnaire was completed',
        'Fixed various mapping issues in the AIS POC'
      ],
      newFeatures: [
        'Added TSheets Clock in/out button to header',
        'Added ability to search by exact flags or blank flags (strict checkbox)',
        'Added Other dropdown select field functionality to case custom fields w/ ability to type in the other value',
        'Added Dupe notification functionality to Create New Opp screen for F+L Name, phone, and email fields',
        'Added POC Filing Email for copy to use in POC File procedure',
        'Added Country as an available field on the address client info section',
        'Added functionality for Event Actions to be configured on a campaign level (i.e. email on status change)',
        'Added Email Account to settings for usage in Event Actions',
        'Comments shown on Cases table are now capped at 100 chars',
        'Updated download csv functionality on opps table to work more smoothly',
        'Updated Settings area to be separated into tabs',
        'Updated Status settings area to be able to edit existing statuses and better create and view the statuses',
        'Changed Assuresign sign notification handler to update the status to Signed and Ready no matter the current status (except qualified sent)',
        'Added Field to hold Filed Case # for POC filing process',
        'Added a history log entry when a webhook to an external service is fired (i.e. Velawcity)',
        'Updated the history to have a more user friendly look of what exactly changed'
      ]
    },
    {
      version: '2020.09.16',
      bugFixes: [
        'Fixed bug causing old data to sometimes overwrite newly entered data in Opps',
        'Fixed Tsheets clock out bug not showing clock out button after 6pm due to timezone bug',
        'Fixed Do Not Mail filter in Cases table'
      ],
      newFeatures: [
        'Added functionality to Strict Search for Opps/Cases with no flags',
        'Removed intake user access to Cases area',
        'Added DLP to list of ZS campaigns to be handled differently on POC sending',
        'Beta web phone config!'
      ]
    },
    {
      version: '2020.09.21',
      bugFixes: [
        'Fixed Opp transfer wizard so that the answers get transferred correctly',
        'Campaign Copy bug fixed so it now copies questions over to new campaign',
        'History bug fixed so it is not assigning incorrect users to a history change',
        'Fixed bug in campaigns that was not allowing you to set the Is Visible to Intake and save it.'
      ],
      newFeatures: [
        'POC set to specific attorney info for Boy Scouts R&B and AIM Boy Scouts campaigns',
        'TSheets clock out button now checks if you are still checked into a pbx queue and prevents clock out until you sign out of pbx. Also unlocks any opps you currently have locked.',
        'Campaign Copy now also transfers assignable users'
      ]
    },
    {
      version: '2020.09.28',
      bugFixes: [
        'Fixed some issues with the dupe notifications on Create New Opp',
        'Fixed document attachment area to allow multiple uploads and deletions w/o refreshing',
        'Fixed Comments bug that was causing case comments to get copied over Opp comments, and restored opp comments on affected opps.'
      ],
      newFeatures: [
        'Added Case Questionnaire Split View for easy Intake to Client questionnaire mapping',
        'Initial version of Manager Dashboard',
        'Re-worked User Management and Auth0 sync for better stability (should be no visible effect for front end users)',
        'Added functionality to add flag AIS-Q-COMPLETED-AFTER-S1 if a questionnaire comes in from cognito and the case status is not 1.',
        'Removed Firm users from user select dropdowns for Opps',
        'Added bulk case assign functionality'
      ]
    },
    {
      version: '2020.10.19',
      bugFixes: [
        'Fixed Case Status to be label instead of Slug on Opportunities',
        'Fixed various filters in Cases table (age, DOB, do not mail)',
        'Fixed Campaigns search and pagination bugs',
        'Fixed multi/re-load issues and Welcome/login screen flashes'
      ],
      newFeatures: [
        'Added Case Comments for view only in Opportunities',
        'Better UI for Flag Change History Items',
        'Added slug to label in campaign custom field copy dialog',
        'Added validation for adding case custom fields in campaigns so you cant add blank ones',
        'Made Assigned User Filter in cases table a multi-select dropdown instead of straight text box',
        'Added supporticle access to firm users and restricted them from seeing non-firm articles',
        'Added more features the the new Event Action system including Notification Action type and merge fields in Email body',
        'Added bulk assign users functionality (this was pushed as a hotfix last week)',
        'Added ability to set Status on createOpp REST API call',
        'Added different color favicon to more easily tell Staging vs. Production environments apart',
        'Made Case Statuses a multi-select in opportunities filter',
        'Updated AIS Stats report to go by case statuses only (no more flag counts)',
        'Optimized page loading structure for faster loads'
      ]
    },
    {
      version: '2020.10.21',
      bugFixes: [
        'Fixed the Case Filter to be able to search by No Assigned User set',
        'Fixed the upload document functionality to allow special chars in the file name'
      ],
      newFeatures: [
        'Added POC Filed Attorney Signature column and KL Review column to AIS Stats report',
        'Added spookiness factor'
      ]
    },
    {
      version: '2020.11.02',
      bugFixes: [
        'Fixed Case Column selection persistance issue',
        'Fixed sort by Case Status not going in alpha/numerical order of status label bug'
      ],
      newFeatures: [
        'Added turkey factor',
        'Added POC send to alternate signer (attorneys) functionality',
        'Added realtime list of Users currently Working on this Case avatars at the top of the cases edit screen',
        'Added automatic close of Case Edit screen after 5 mins of inactivity',
        'Added POC and Questionnaire Date fields for Cases',
        'Added Event Action functionality for auto date setting',
        'Added GMail data miner to get AIS POC Claim Numbers from Omni notifications',
        'Updated header colors on staging for opp and case edit screens so as not to mix up with prod as easily',
        'Updated Status management section so that add/edit status pops up a form modal box for cleaner look'
      ]
    },
    {
      version: '2020.11.03',
      bugFixes: [
        'Fixed double loading issue on Case Edit',
        'Fixed Cases table pagination bug',
        'Fixed POC issue that was disabling send button on attorney sign procedure'
      ],
      newFeatures: [
        'Made flags non-editable for firm users',
        'Updated inactivity timer from 5 to 15 mins before it kicks you out of a case',
        'Added new Attorney Sign statuses to the AIS Stats report page'
      ]
    },
    {
      version: '2020.11.25',
      bugFixes: [
        'Fixed buggy Cases Table pagination'
      ],
      newFeatures: [
        'Apply Filters button in Cases Filters can now be triggered by Enter button',
        'Duplicate checker when creating new opps has been refactored to be more user friendly (now provides a list on the auto-save when required fields are set dialog)',
        'Claim Filing # field on Cases POC tab can now be blanked out and saved',
        'Added Eversign integration to the documents area on both Opps and Cases so that you can see/resend/cancel any Eversign documents attached to the opp or case',
        'Removed Opp locking in favor of realtime collaboration functionality (avatars on table and edit screens, snackbar notifications on changes)',
        'Event Action Emails can now also include attachments based on given pattern',
        'Event Action to set a date stamp now has an overwrite or not option',
        'Events can now trigger a POST request action that allows simple integration with other systems',
        'Users now have the option to bypass event actions',
        'Admins can now bulk change statuses on the cases table',
        'Optimized Opps and Cases tables and Edit screens for faster loading',
        'Recently Accessed Opps/Cases list shortcut buttons added to top of tables',
        'Campaigns can now belong to multiple groups',
        'The import tool can now be used to import externalIds and case statuses',
        'The import tool can now be used to Update opps in addition to adding new ones',
        'Assuresign integration will no longer produce warning emails when the opp status is already in qualifiedSent',
        'Replaced the In-activity timeout on the cases and opportunity with a refresh instead of a return to the tables',
        'Filter Chips on Cases table now are labeled'
      ]
    },
    {
      version: '2021.01.03',
      bugFixes: [
        'Fixed commas causing issues in multi-select case questionnaire options',
        'Fixed sorting and filtering on some random Opp fields',
        'Fixed co-counsel email saving issues',
        'Fixed saving issues across cases and opps so that typing doesnt get erased during save.'
      ],
      newFeatures: [
        'Added Not flag search functionality',
        'Added code tests for case questionnaire',
        'Added Co-Counsel Portal + Pin merge fields to Event Action emails',
        'Added merge fields to Event Action email subject lines',
        'Tech-Debt fixed outdated package issues, updated to latest and greatest',
        'Added Client Comments functionality for client portal comms',
        'Added BETA client portal for testing.'
      ]
    },
    {
      version: '2021.01.18',
      bugFixes: [
        'Fixed Assuresign issue where status was getting updated to signed & ready prematurely',
        'Fixed issue where client info was not saving after creating a new opp and then filling in intake questions',
        'Fixed issue where you couldn\'t see newly created firms',
        'Fixed transfer tool issue where you couldn\'t transfer an opp that had already been transferred previously',
        'Fixed smartystreets address verification so that it correctly says verified when you initially open an opp (if it is a good address)',
        'Fixed sorting issue on status grid that was causing wrong status info to be displayed on the edit screen',
        'Fixed issue causing blank Client Info sometimes when opening a case',
        'Fixed issue with co-counsel portal not displaying intake answers',
        'Fixed issue with split view in cases not saving',
        'Fixed issue with DOB column always showing in Opp Table',
        'Fixed issue with download csv not allowing you to download the results',
        'Fixed issue where Opps were getting overwritten with previously worked on opp',
        'Fixed caching issue in campaign management where campaign changes were not being shown immediately',
        'Fixed staging data scrambler to change all emails to *.rdevs.com to avoid accidentally emailing real people'
      ],
      newFeatures: [
        'Zip files can now be uploaded',
        'Added Filed Case # field to Documents tab in Cases for access on Non-AIS cases',
        'Set AIS Cases to only see specific AIS Case Statuses',
        'Added R&B Boyscouts campaign to be able to see AIS POC tab in cases',
        'Added Client Portal initial roll out including functionality to create secure links to the client portal to be sent to an email or phone',
        'Updated Comments area layout to not stack which was causing confusion',
        'Added Case Priority field',
        'Updated Campaign Case Custom Fields table in case management to new grid and add/edit interface',
        'Added Campaign Status and Grouping in campaign drop downs for better organization of Active/Inactive campaigns',
        'Changed the Edit button that you click on to open the Opp or Case details so that it is now combined with the ID column (rather than a ... button or edit icon button)',
        'Added the Is Case field on opportunities to indicate an Opp has become a case rather than based on the qualifiedSent status',
        'Added a new Event Action to allow the new Is Case field to get set based on a status change'
      ]
    },
    {
      version: '2021.02.02',
      bugFixes: [
        'Fixed issue with opening and closing Opps quickly, which caused some changes to get lost or overwritten',
        'Added back the clear filters button',
        'Fixed various issues causing some slowdowns/lockups/endless spinning scales of justice',
        'Fixed Last Updated field on the cases side to work like the opp side one that is more reflective of user changes'
      ],
      newFeatures: [
        'Added Eversign Webhook Event to the Event Actions system',
        'Added Case Details tab in Opportunity for better visibility to intake staff',
        'Added Eversign Document Template functionality',
        'Added User Notifications system',
        'Added functionality to allow for campaign specific statuses',
        'Added Client Portal Access Event to the Event Actions system'
      ]
    },
    {
      version: '2021.02.12',
      bugFixes: [
        'Fixed issue where Campaign specific statuses were not being saved while setting in the campaign status config tab',
        'Fixed issue with Eversign templates being sent but not updating status to waiting on esign',
        'Fixed issue on Create Opp where it was popping up the save before you could finish typing in the full phone number',
        '* Fixed issue with Case Details tab not being accessible to intake users',
        '* Fixed issue with retainer docs not showing on co-counsel portal',
        '* Fixed various issues with Assuresign documents not being sent and/or not updating opp status when signed',
        '*Hotfix, already live on production)'
      ],
      newFeatures: [
        'Added functionality to Event Actions system so that you can send a Notification to a user base on an Event',
        'Added ability to filter the history entries by field in the opp detail view history tab',
        'Added an expand all button to history tab in opp detail view',
        'Added functionality to Event Actions system so that you can create an Event based on the status change FROM a status (rather that TO a status)',
        'Refined the User menu in the top right and added a User Profile page for user specific details',
        'Added ability for users to review and confirm bonuses',
        'Refined the ESign Documents area so the Action buttons are handled more cleanly and added Sent By, Sent Date, and Signed date columns',
        'Added functionality to Event Actions system so that you can handle an incoming Eversign webhook notification on a campaign basis however is needed',
        'Added realtime update to Opp Detail view so the status will automatically update if an eversign signed webhook notification comes in',
        'Added functionality in Cases Table to be able to bulk update Case Statuses',
        'Added ability to send notifications to just internal users'
      ]
    },
    {
      version: '2021.02.26',
      bugFixes: [
        'Fixed issue with pagination of Opportunities grid sometimes causing repeat results on subsequent pages',
        'Fixed issue on Event Actions where newly added EAs were not showing on the list until refresh',
        'Fixed issue in Campaigns Management grid with sorting',
        'Fixed issue where the SMS conversation is not showing replies in the Comms Tab',
        'Fixed issue with Case Status not showing correctly in the opps grid',
        'Fixed issue where questionnaire fields on Opp Case Details tab were not showing answer values',
        '* Fixed issue with finance tab not showing correctly on non-AIS cases',
        '* Fixed issue with Eversign Send SMS button showing on ESign docs that dont have a text-able link',
        '*Hotfix, already live on production'
      ],
      newFeatures: [
        'Added Inmate ID and Correctional Facility as fields in the Client Info section of Cases/Opps and on the Grids as filterable columns',
        'Added Case Assignee to Event Actions as a possible notification/email recipient.',
        'Added History Expand All button',
        'Added auto format of Phone Number',
        'Added @ mention user notifications in comments',
        'Added attorney fee splits and projected attorney fees to case financial fields',
        'Updated campaign custom statuses so that you can only select one default opportunity and case status',
        'Updated backend packages to latest versions and schemas so to better stabilize the system (faster builds, optimized GraphQL API Client)',
        'Updated user authentication configuration for stabilization and speed increases'
      ]
    },
    {
      version: '2021.03.18',
      bugFixes: [
        'Fixed issue with my profile page going blank after a short period of time',
        'Fixed issue with opportunity grid filtering and showing no results rows but displaying a number in the pagination area',
        'Fixed issue with case questionnaire saving and undoing changes',
        'Fixed issue where a user could not load the last page of opportunities in the opportunities table',
        'Fixed issue that when changing the case status it would take several seconds and auto-saves before actually setting the status',
        'Fixed issue where cases created from the opportunity isCase toggle when navigated to from the opp fsd to the case fsd and then closed would get stuck loading',
        'Fixed issue where questions would not load on the campaign questions tab',
        'Fixed issue with case priority not saving',
        'Fixed issue when clicking through pages of opportunities that the order and pages are inconsistent',
        'Fixed issue with date fields in the intake questionnaire causing the page to error',
        'Fixed issue that caused the screen to go white because of an unset variable',
        'Fixed issue where case questionnaire had no answers when viewed in the case details tab from the opportunity',
        'Fixed issue where newest version of node cause assignment errors in the campaigns fsd',
        'Fixed issue eversign documents where not updating the opportunity status when the webhook for signed document came in',
        'Fixed issue when creating or editing an event action it would error and not save',
        'Fixed issue where case questionnaire was being overwritten by remote changes',
        'Fixed issue where filtering by case status in the cases page was displaying the id instead of the label and not showing any results',
        'Fixed issue where queries were failing when running on the server without authentication',
        'Fixed issue where the case was saving multiple times on one change',
        'Fixed issue where the campaigns table was locking up when searching for "ais" or showing 250 results',
        'Fixed issue where opening an opp would immediately show a pop up asking if you wanted to remove a flag',
        'Fixed issue where searching for "RB Childhood..." in supporticles would return articles not related',
        'Fixed issue where removing the space from the end of an email would not save'
      ],
      newFeatures: [
        'Added follow up as an opportunity table filter',
        'Added drop down to the loading animation for debugging',
        'Added confirmation before deleting a flag from an opportunity',
        'Added Cases Stats Dashboard for admin users',
        'Added Loan tracking to cases to reduce the use of external spreadsheets',
        'Updated case assignable users to only show users with permission to access that campaign',
        'Updated the campaigns table to the new style Grid table with built in filtering and sorting',
        'Added to automated test suite user flow coverages for Co-Counsel Portal and Event Actions',
        'Updated software packages and made various "Tech Debt" upgrades for smoother, faster caseopp User experience'
      ]
    },
    {
      version: '2021.03.29',
      bugFixes: [
        'Fixed issue where filtering in cases cause all cases to be red or lose status color',
        'Fixed issue where making changes in the case triggered client portal link notification bar',
        'Fixed issue with Opp Transfer tool erroring out'
      ],
      newFeatures: [
        'Added an event action that will set the case or opp status based on the opp or case status respectively',
        'Added the ability to set a future date for notifications',
        'Added the ability to snooze a notification',
        'Added the ability to send notifications from the full screen dialog',
        'Added the ability for users to send themselves notifications',
        'Added the ability for admins to select multiple individual users to send notifications to',
        'Added history to the cases full screen dialog',
        'Added notifications to the profile page to see past done notifications',
        'Updated eversign to allow multiple uses of the same identifier',
        'Updated eversign to not require full refresh on business id change',
        'Updated eversign dob field to format to readable',
        'Updated eversign config so you can set the FROM name and email to a custom value'
      ]
    },
    {
      version: '2021.04.13',
      bugFixes: [
        'Fixed issue where old (bin) files were missing',
        'Fixed issue where the use of user roles caused notification errors',
        'Fixed issue where priority new leads were not displaying accurately',
        'Fixed issue where campaign custom fields caused the screen to blank out',
        'Fixed issue where transferring an opp cause questions to be blank',
        'Fixed issue where page made a full refresh when opening an opportunity',
        'Fixed issue with zipwhip not sending properly',
        'Fixed issue where Notification Messages were not displaying the spacing and formatting correctly'
      ],
      newFeatures: [
        'Amazon Connect Web Phone initial integration',
        'New document template system added',
        'Added Dupe check button and auto-pop on newLeads',
        'Added an event action to send an eversign document',
        'Added ability to specify campaign specific flags',
        'Added ability to search by case comments in the case table',
        'Added ability to assign statuses to all campaigns of a selected group',
        'Updated the user select in creating notifications to enable multi-select for individuals',
        'Upgraded User Authorization system to more granular Role/Permissions based controls'
      ]
    },
    {
      version: '2021.04.21',
      bugFixes: [
        'Fixed Dupe Opp Checker multiple pop',
        'Fixed issue in Eversign Send where if there is not a valid email then it will fallback to just sending a txt link',
        'Fixed errant permissions that were allowing intake users to see the cases and manager dashboards',
        'Fixed issue where the Firm user was seeing a blank action menu in their header bar'
      ],
      newFeatures: [
        'Added DID # and description on incoming calls queue for connect webphone',
        'Upgraded Event Actions so that you can have an Event trigger on more than just one status',
        'Upgraded Event POST Action so that you can further customize the payload for webhooks',
        'Added Publisher field in the core attributes to hold more data on where a lead comes from',
        'Added Disqualified reason field in the core attributes for a dedicated place to hold DQ info',
        'Updated Release Notes section for easier perusal',
        'Various speed improvements made for better UX'
      ]
    },
    {
      version: '2021.05.12',
      bugFixes: [
        'Various speed optimizations including Users query that was consistently causing endless spinners',
        'Fixed user Name list to be sorted alpha',
        'Repositioned/Resized Opp Grid Comments popover to not go off screen',
        'Fixed issue with Event Action settings interface not working when multiple status triggers were selected',
        'Fixed home page issue with empty grid for some users',
        'Fixed Dupe Checker flash on non-new-lead opps',
        'Fixed issue not allowing ctrl z undo in phone field',
        'Fixed issue preventing eversign from sending the doc over email for signing',
        'Fixed issue with doc template editor not properly showing the editor'
      ],
      newFeatures: [
        'Updated Campaign settings Co-Counsel Portal Editor to be WYSIWYG style instead of straight code.',
        'Setup Campaign Settings tabs to be vertical instead on top and organized settings for better UX',
        'Added Intake Q/A as available merge fields in the Co-Counsel Portal Editor and Doc Template Editor',
        'Added Connect phone floating icon and right side drawer open on any page to access phone',
        'Setup phone to open on incoming call to best notify the user of an incoming call',
        'Added call history to Amazon Connect Phone',
        'Setup search opps functionality for incoming Connect Phone contact',
        'Added quick copy buttons next to phone numbers for easy use with connect phone',
        'Added DID Management to caseopp so we can attach them to campaigns for better integration with amazon connect phone',
        'Added updateOpp REST API endpoint for better integration with client partners',
        'Added allowed campaigns field on API Tokens for better security'
      ]
    },
    {
      version: '2021.05.21',
      bugFixes: [
        'Fixed bug in load section requiring reload to see the updated values',
        'Fixed bug causing opps w/o event actions attached to their campaign to error on save',
        'Fixed phone column not being removed if unchecked from columns bug',
        'Issue fixed which was causing campaign level templates to not show in the list after initial creation'
      ],
      newFeatures: [
        'Updated home page to show all priority new leads to admins and supervisors for easier workflow and faster loading',
        'Admins can now configure a specific set of disqualification reasons on a campaign by campaign basis so the users have to choose from a dropdown'
      ]
    },
    {
      version: '2021.06.04',
      bugFixes: [
        'Updates made for more consistent visibility on who is working on what Opps',
        'Fixed flags not immediately updating in flag management area after adds/edits',
        'Added optimizations to the main Opportunities grid to speed it up'
      ],
      newFeatures: [
        'Added new Amazon Connect Voicemail management page',
        'Upgrades to User management area including ability to add/remove permissions',
        'Opportunity and Case URLs now reflect the tab you are on so you can copy/paste a link to a specific tab on a specific opp or case'
      ]
    },
    {
      version: '2021.06.08',
      bugFixes: [
        'Fixed issue causing white screen on opps in inactive campaigns for intake users'
      ],
      newFeatures: [
        'Added Handled and Assigned User fields to Voicemails'
      ]
    },
    {
      version: '2021.06.11',
      newFeatures: [
        'Added ability to set a users homepage',
        'Added new page "Intake Dashboard" for better workflow management of IPs and CMs',
        'Re-factored the old "Cases Dashboard" into "Campaign Stats" page for better visibility into how campaigns are performing'
      ]
    },
    {
      version: '2020.06.24',
      bugFixes: [
        'Fixed white screen errors when exiting an Opp sometimes',
        'Fixed issue with Call history disappearing and returning with incorrect timestamp',
        'Fixed Intake Dashboard Graph',
        'Fixed filters being lost occasionally on exit from Opp Edit screen',
        'Fixed links on Recently Accessed Opps list so they work properly AND you dont lose filters by going to them',
        'Fixed issue where Assuresign docs were sometimes being duplicated and not attaching to the Opp correctly causing Signed status updates not to work',
        'Fixed issue where sometimes the Case Manager assignment was being overwritten'
      ],
      newFeatures: [
        'Added status color coding to recently access opps list',
        'Upgraded the Saved Filters functionality to be able to name and edit your filters and better manage them in a grid',
        'Voicemails for assigned numbers are now hidden from the main Voicemail list',
        'You can now toggle on Injured Party is Different that exposes specific injured party info fields in an Opp',
        'Auto-Assign V1 for new incoming leads (toggled off in the settings until management gives the go ahead)',
        'TimeZone functionality based on Area Code or Address is now available in the Opps Grid and Opp Edit screen'
      ]
    },
    {
      version: '2021.07.13',
      bugFixes: [
        'Removed all Nexmatrix queue / PBX integrations',
        'Use header space to display page title or other info such as logged in user name.',
        'Fixed issues with invalid timezones causing various white screens of death (hotfix)',
        'Fixed download page re-run report button (hotfix)',
        'Fixes issue where injuredPartyDifferent toggle was not matching what was showing in client info',
        'Fixed issue where history was blaming the wrong person',
        'Fixed issue where voicemails would not load',
        'Fixed issue with filters trying to load while user is still selecting columns',
        'Fixed issue where filter would not update opp table',
        "Fixed issue where emails and phone numbers don't find duplicate opps",
        'Fixed issue with downloads being killed by page updates',
        'Fixed campaign goals and statuses not updating',
        'Fixed issue where dupe search was not finding some email or phone dupes',
        'Fixed issue where new users were not being added to dropdown lists',
        'Fixed issue with auto-complete that caused white screens'
      ],
      newFeatures: [
        'Added Custom Event Action Warning Message to the Event Action Setup Dialog',
        'Added internal Email functionality',
        'Made it so that as you navigate around the Campaign FSD the URL is updated which allows you to share and refresh without losing your place',
        'Added the ability to have eversign documents that are useable upon admin approval',
        'Added active/disabled toggle to Event Actions',
        'Organized Opportunities Columns selector for easier use'
      ]
    },
    {
      version: '2021.07.26',
      bugFixes: [
        "Fixed issue where auto assign wouldn't work on production",
        'Made Eversign Webhook work when the same document is sent multiple times',
        'Fixed issue where dupe search was not finding some email or phone dupes',
        'Fixed issue with auto-complete that caused white screens',
        'Fixed issue with fetching updated Opp info on entering FSD',
        'Fixed issue with user closing tab or window without exiting the Opp FSD and still appearing to be in FSD to other users',
        'Fixed issue where '
      ],
      newFeatures: [
        'Added backdrop flash when an opp is edited by more than one user',
        'Added Error logging with user tagging',
        'Added dupe search value so agent doesnt have to click to see values',
        'Updates campaign priority to default to Low if unset',
        'Added Dids and Last Updated to the campaigns table',
        'Added more amazon phone error logging',
        'Added New Leads section to the Intake Dashboard',
        'Added pusher implementation for notifications',
        'Added Payments to Opportunities so that we can track the flow of money'
      ]
    },
    {
      version: '2021.08.12',
      bugFixes: [
        'Updated Bulk Opp Transfer Tool to be able to handle more opps at once',
        'Fixed issue where sent eversign docs were missing the sent date in the history tab',
        'Fixed issue where campaign conversion rate and marketing budget values were not saved',
        'Fixed issue where campaign fsd would double load',
        'Fixed issue where a saved filter on one page would override other tabs on refresh',
        'Fixed issue where the document downloads where expired',
        'Fixed issue where smsTemplates were attempting to load from blank campaign data, resulting in page whitescreen',
        "Fixed issue where intakeDashboard charts were returning values that didn't correspond with the Opp search",
        "Fixed issue where copied doc templates weren't showing in campaigns email tab",
        'Fixed issue where sentDate on eversign documents was not getting set',
        'Fixed issue where the document downloads where expired',
        'Fixed issue where voicemail assignments and handled toggle were not saving'
      ],
      newFeatures: [
        'added coCounsel portal mock user to process of authentication for coCounsel Portal graphql calls',
        'added role based filtering of case custom fields and configuration ability of those fields on an individual basis',
        'Event Action POSTs can now supply links to documents on Google Cloud Storage (aka "Other Documents") via an {{otherDocs}} tag',
        'Added ability for intake staff to copy an opportunity to a new campaign if campaign setup allows it',
        'disabled all visible client info fields for the client portal and hid many others that they do not need to see',
        'Added dynamic date ranges for saved searches',
        'Added deadline field to opportunities and cases',
        'Fixed issue where campaign conversion rate and marketing budget values were not saved',
        'Fixed issue where campaign fsd would double load',
        'Added new option on Flag Create/Edit Dialog in settings that allows you to select which roles can remove the flag',
        'Upgraded Voicemail Table Functionality',
        'Changed intakeDashboard Bar charts with new Pie Charts',
        'Added daily updated contact counts to be used for user dashboards'
      ]
    },
    {
      version: '2021.08.24',
      bugFixes: [
        'Fixed issue where alternateName not existing caused the injuredParty to be marked as different',
        'Fixed issue in updateDailyIntakeTargets due to server side time differences',
        'Added groups to users in Auth0 and user management table',
        'fixed Campaign copy feature that was creating three campaigns instead of one',
        'Fixed issue with user closing tab or window without exiting the Opp FSD and still appearing to be in FSD to other users',
        'Fixed issue with looping and duplicate email entries'
      ],
      newFeatures: [
        'Made the opportunity data update with important information when the opp saves',
        'Removed ability to post comments from tables',
        'Added Contact Attempt field to comments',
        'Added flags to import/update tool',
        'Upgraded release notes page',
        'Added new "Partner Portal" alpha version'
      ]
    }
  ]
}
