import PropTypes from 'prop-types'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from '../graphql/client'
import { setGlobal } from 'reactn'
import CssBaseline from '@mui/material/CssBaseline'
import 'react-quill/dist/quill.snow.css'
import { useEffect } from 'react'
import { clientWindow } from '../src/helpers/helper'
import '../src/styles/globalStyles.css'
import '../src/styles/colorPicker.module.css'
import { LicenseInfo } from '@mui/x-data-grid-premium'
import { UserProvider } from '@auth0/nextjs-auth0'

LicenseInfo.setLicenseKey(
  process.env.MUIX_LICENSE_KEY
)

const thisFile = '_app ' // eslint-disable-line no-unused-vars
export default function CaseOppApp ({ Component, pageProps, err }) {
  const apolloClient = useApollo(pageProps)

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
    setGlobal({
      isLoading: false,
      loadingMessage: 'Loading Page',
      loadingStack: [],
      loggedIn: false,
      loggedInUser: null
    })
  }, [])

  let favicon = '/favicon.png'
  if (clientWindow) {
    if (clientWindow.location.href.indexOf('sta.caseopp.com') > -1) {
      favicon = '/favicon-sta.png'
    } else if (window.location.href.indexOf('localhost:3000') > -1 || window.location.href.indexOf('vercel.app') > -1) {
      favicon = '/favicon-local.png'
    }
  }

  return (
    <UserProvider>
      <ApolloProvider client={apolloClient}>
        <Head>
          <title>CaseOpp</title>
          <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
          <link rel='icon' type='image/x-icon' href={favicon} />
        </Head>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...pageProps} err={err} />
      </ApolloProvider>
    </UserProvider>
  )
}
CaseOppApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object
}
