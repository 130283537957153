// sentry.client.config.js

import * as Sentry from '@sentry/nextjs'
import {
  CaptureConsole as CaptureConsoleIntegration,
  Dedupe as DedupeIntegration,
  Offline as OfflineIntegration
} from '@sentry/integrations'
import { baseContext } from './src/helpers/helper'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN,
  environment: baseContext, // ['pro', 'sta', 'demo', 'dev'], 'dev' will not pass to sentry since NODE_ENV === 'development' for enabled field
  enabled: process.env.NODE_ENV === 'production', // vercel previews and sta are also "production", see environment
  beforeSend (event) {
    // Modify the event here
    if (event && event.request.url.includes('phone')) {
      return null // unfortunately amazon logs are garbage
    }
    if (event && event.message && event.message.indexOf && (~event.message.indexOf('Warning:') || ~event.message.indexOf('[BABEL] Note:'))) {
      // skip event log
      return null
    } else {
      return event
    }
  },
  integrations: [new CaptureConsoleIntegration(
    {
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error']
    }
  ),
  // This integration deduplicates certain events; it can be helpful if you are receiving many duplicate errors. Be aware that Sentry will only compare stack traces and fingerprints.
  new DedupeIntegration(),
  new OfflineIntegration(
    {
      // limit how many events will be locally saved. Defaults to 30.
      maxStoredEvents: 30
    }
  )
  ]
})
