import HomeIcon from '@mui/icons-material/Home'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import HelpIcon from '@mui/icons-material/Help'
import InputIcon from '@mui/icons-material/Input'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import NotesIcon from '@mui/icons-material/Notes'
import CloudIcon from '@mui/icons-material/SettingsSystemDaydream'
import VoicemailIcon from '@mui/icons-material/Voicemail'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import PollIcon from '@mui/icons-material/Poll'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import NotificationsIcon from '@mui/icons-material/Notifications'
import BugReportIcon from '@mui/icons-material/BugReport'
import GavelIcon from '@mui/icons-material/Gavel'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import HailIcon from '@mui/icons-material/Hail'
import TuneIcon from '@mui/icons-material/Tune'
import PhoneCallback from '@mui/icons-material/PhoneCallback'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'

export const userFacingOppFieldNamesDict = {
  displayId: 'ID',
  campaign: 'Campaign',
  campaignId: 'Campaign',
  source: 'Source',
  origination: 'Origination',
  status: 'Status',
  timestamp: 'Date Created',
  dateSentClient: 'Doc Sent To Client',
  dateSigned: 'Doc Signed',
  dateSentFirm: 'Doc Sent To Co-Counsel',
  user: 'User',
  intakeUser: 'Intake Rep',
  paralegalUser: 'Paralegal',
  attorneyUser: 'Attorney',
  deadline: 'Deadline',
  reviewUser: 'Review User',
  caseManagerUser: 'Case Manager', // display
  managerUser: 'Case Manager', // database
  firstName: 'First Name',
  lastName: 'Last Name',
  alternateName: 'Name if different than Client',
  email: 'Email',
  phone: 'Phone',
  phoneExtension: 'Phone Extension',
  phoneType: 'Phone Type',
  alternatePhone: 'Alternate Phone',
  alternateExtension: 'Alternate Phone Extension',
  alternatePhoneExtension: 'Alternate Phone Extension',
  alternatePhoneType: 'Alternate Phone Type',
  addressStreet: 'Street Address',
  addressUnit: 'Address Unit',
  addressCity: 'City',
  addressState: 'State',
  addressZIP: 'ZIP Code',
  addressCountry: 'Country',
  timeZone: 'Time Zone',
  autoTimeZone: 'Auto Set Time Zone',
  inmateId: 'Inmate ID',
  correctionalFacility: 'Correctional Facility',
  dob: 'Date Of Birth',
  address: 'Address',
  comment: 'Comment',
  updated: 'Last Updated',
  name: 'Name',
  followUp: 'Follow Up',
  author: 'Author',
  notes: 'Notes',
  flagIds: 'Flag',
  flagIdsStrict: 'Strict Flag',
  priority: 'Campaign Priority',
  doNotMail: 'Do Not Mail',
  doNotCallPhone: 'Do Not Call Phone',
  doNotTextPhone: 'Do Not Text Phone',
  doNotCallAltPhone: 'Do Not Call Alternate Phone',
  doNotTextAltPhone: 'Do Not Text Alternate Phone',
  doNotEmailPrimary: 'Do Not Email Primary',
  doNotEmailSecondary: 'Do Not Email Secondary',
  questionnaireURL: 'AIS Questionnaire URL',
  pin: 'Pin',
  clientView: 'Co-Counsel Portal',
  caseStatus: 'Case Status',
  caseStatusUpdated: 'Case Status Updated',
  campaignGroups: 'Campaign Groups',
  campaignStatus: 'Campaign Status',
  campaignStatuses: 'Campaign Statuses',
  assureSignDocuments: 'AssureSign Documents',
  createdAt: 'Opportunity Created',
  preferredName: 'Preferred Name',
  publisher: 'Publisher',
  dqReason: 'Disqualification',
  middleName: 'Middle Name',
  secondaryEmail: 'Secondary Email',
  suffix: 'Suffix',
  title: 'Title',
  answers: 'Answers',
  pocFiledDate: 'POC Filed',
  pocSignedDate: 'POC Signed',
  pocSentForSigningDate: 'POC Sent',
  filedCaseNumber: 'Filed Case #',
  claimId: 'Claim ID',
  ballotId: 'Ballot ID',
  exclude: 'Exclude',
  isCase: 'Is Case',
  clientPortalLastAccessed: 'Date Client Last Logged In',
  clientComment: 'Client Comment',
  clientCommentAuthor: 'Client Comment Author',
  caseComment: 'Case Comments',
  caseCommentAuthor: 'Case Comment Author',
  incomingPhone: 'Incoming Phone',
  externalId: 'External ID',
  negotiatedRetainerApproval: 'Negotiated Retainer Approval',
  injuredPartyDifferent: 'Injured Party Different',
  injuredPartyFirstName: 'Injured Party First Name',
  injuredPartyLastName: 'Injured Party Last Name',
  injuredPartyDOD: 'Injured Party Date of Death',
  injuredPartyDOB: 'Injured Party Date of Birth',
  injuredPartyGender: 'Injured Party Gender',
  injuredPartySSN: 'Injured Party SSN',
  injuredPartyRelation: 'Caller\'s Relationship to Injured Party',
  gender: 'Gender',
  callbackDateTime: 'Callback Time',
  contactAttemptsCount: 'Contact Attempts',
  firstRetainerSender: 'First Retainer Sent By',
  legalAssistant: 'Legal Assistant',
  litigationSupportUser: 'Litigation Support Specialist'
}

export const userFacingOppFieldNamesImportDict = {
  displayId: 'Display ID',
  source: 'Source',
  origination: 'Origination',
  status: 'Status',
  timestamp: 'Date Created (will default to now if blank)',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  alternateName: 'Name if different than Client',
  suffix: 'Suffix',
  publisher: 'Publisher',
  dqReason: 'Disqualification',
  email: 'Email',
  phone: 'Phone',
  phoneExtension: 'Phone Extension',
  phoneType: 'Phone Type',
  alternatePhone: 'Alternate Phone',
  alternateExtension: 'Alternate Phone Extension',
  alternatePhoneType: 'Alternate Phone Type',
  addressStreet: 'Street Address',
  addressUnit: 'Address Unit',
  addressCity: 'City',
  addressState: 'State',
  addressZIP: 'ZIP Code',
  addressCountry: 'Country',
  timeZone: 'Time Zone',
  autoTimeZone: 'Auto Set Time Zone',
  inmateId: 'Inmate ID',
  correctionalFacility: 'Correctional Facility',
  dob: 'Date Of Birth',
  notes: 'Notes',
  comments: 'Comments',
  caseComment: 'Case Comments',
  doNotMail: 'Do Not Mail',
  doNotCallPhone: 'Do Not Call Phone',
  doNotTextPhone: 'Do Not Text Phone',
  doNotCallAltPhone: 'Do Not Call Alternate Phone',
  doNotTextAltPhone: 'Do Not Text Alternate Phone',
  doNotEmailPrimary: 'Do Not Email Primary',
  doNotEmailSecondary: 'Do Not Email Secondary',
  narrative: 'Case Narrative',
  externalId: 'External ID',
  caseStatus: 'Case Status',
  isCase: 'Is Case',
  caseStatusUpdated: 'Case Status Updated',
  negotiatedRetainerApproval: 'Negotiated Retainer Approval',
  injuredPartyDifferent: 'Injured Party Different',
  injuredPartyFirstName: 'Injured Party First Name',
  injuredPartyLastName: 'Injured Party Last Name',
  injuredPartyDOD: 'Injured Party Date of Death',
  injuredPartyDOB: 'Injured Party Date of Birth',
  injuredPartySSN: 'Injured Party SSN',
  injuredPartyGender: 'Injured Party Gender',
  injuredPartyRelation: 'Caller\'s Relationship to Injured Party',
  gender: 'Gender',
  flags: 'Flags',
  flagIds: 'Flag Ids',
  priority: 'Priority',
  intakeUser: 'Intake Rep',
  managerUser: 'Case Manager',
  paralegalUser: 'Paralegal',
  legalAssistant: 'Legal Assistant',
  litigationSupportUser: 'Litigation Support Specialist',
  reviewUser: 'Review User',
  attorney: 'Attorney',
  deadline: 'Deadline',
  round: 'Round',
  originIp: 'Origin Ip',
  originURL: 'Origin URL',
  userConsent: 'User Consent',
  userConsentType: 'User Consent Type',
  userConsentIds: 'User Consent IDs'
}

export const userFacingClientInfoFieldNamesDict = {
  displayId: 'ID',
  campaign: 'Campaign',
  campaignId: 'Campaign',
  caseStatus: 'Status',
  caseStatusUpdated: 'Status Updated',
  timestamp: 'Date Created',
  user: 'User',
  title: 'Title',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  contactTime: 'Best Time To Contact',
  preferredName: 'Preferred Name',
  publisher: 'Publisher',
  dqReason: 'Disqualification',
  suffix: 'Suffix',
  email: 'Email',
  secondaryEmail: 'Secondary Email',
  phone: 'Phone',
  phoneExtension: 'Phone Extension',
  phoneType: 'Phone Type',
  alternatePhone: 'Alternate Phone',
  alternatePhoneExtension: 'Alt. Phone Extension',
  alternatePhoneType: 'Alt. Phone Type',
  addressStreet: 'Address line 1',
  addressUnit: 'Address Line 2',
  addressCity: 'City',
  addressState: 'State',
  addressZIP: 'ZIP Code',
  ssAddressSearch: 'Address Lookup',
  timeZone: 'Time Zone',
  autoTimeZone: 'Auto Set Time Zone',
  addressCountry: 'Country',
  inmateId: 'Inmate ID',
  correctionalFacility: 'Correctional Facility',
  dob: 'Date Of Birth',
  notes: 'Notes',
  externalId: 'External ID',
  negotiatedRetainerApproval: 'Negotiated Retainer Approval',
  injuredPartyDifferent: 'Injured Party Different',
  injuredPartyFirstName: 'Injured Party First Name',
  injuredPartyLastName: 'Injured Party Last Name',
  injuredPartyDOD: 'Injured Party Date of Death',
  injuredPartyDOB: 'Injured Party Date of Birth',
  injuredPartySuffix: 'Injured Party Suffix',
  injuredPartySSN: 'Injured Party SSN',
  injuredPartyGender: 'Injured Party Gender',
  injuredPartyRelation: 'Caller\'s Relationship to Injured Party',
  deadline: 'Deadline',
  gender: 'Gender',
  originIp: 'Origin IP',
  originURL: 'Origin URL',
  userConsent: 'User Consent',
  userConsentType: 'User Consent Type',
  userConsentIds: 'User Consent IDs',
  managerUser: 'Case Manager',
  intakeUser: 'Intake Rep',
  paralegalUser: 'Paralegal',
  reviewUser: 'Review User',
  attorney: 'Attorney',
  legalAssistant: 'Legal Assistant',
  status: 'Status'
}

export const userFacingCaseFieldNamesDict = {
  ...userFacingClientInfoFieldNamesDict,
  comment: 'Comments',
  updated: 'Last Updated',
  name: 'Name',
  followUp: 'Follow Up',
  author: 'Author',
  notes: 'Notes',
  externalId: 'Ext. ID',
  flagIds: 'Flag',
  priority: 'Campaign Priority',
  doNotMail: 'Do Not Mail',
  doNotCallPhone: 'Do Not Call Phone',
  doNotTextPhone: 'Do Not Text Phone',
  doNotCallAltPhone: 'Do Not Call Alternate Phone',
  doNotTextAltPhone: 'Do Not Text Alternate Phone',
  doNotEmailPrimary: 'Do Not Email Primary',
  doNotEmailSecondary: 'Do Not Email Secondary',
  narrative: 'Narrative',
  case: 'Case',
  pocFiledDate: 'POC Filed Date',
  pocSignedDate: 'POC Signed Date',
  pocSentForSigningDate: 'POC Sent For Signing Date',
  questionnaireSentDate: 'Questionnaire Sent Date',
  questionnaireCompletedDate: 'Questionnaire Completed Date',
  filedCaseNumber: 'Filed Case #',
  claimId: 'Claim ID',
  ballotId: 'Ballot ID',
  assignedUser: 'Case Assignee',
  loanAmount: 'Loan Amount',
  loanProvider: 'Loan Provider',
  legalAssistant: 'Legal Assistant'
}

const userFacingCampaignFieldNamesDict = {
  name: 'Name',
  description: 'Description',
  firms: 'Firms',
  slug: 'Slug',
  priority: 'Priority',
  counter: 'Counter',
  assureSignDocumentTemplateIds: 'Assure Sign Document Template Ids',
  order: 'Order',
  groups: 'Campaign Groups',
  accountManagers: 'Account Managers',
  assignableUsers: 'Assignable Users',
  defaultOpportunityStatus: 'Default Opportunity Status',
  defaultCaseStatus: 'Default Case Status',
  status: 'Status',
  initCampHistory: 'Initial Campaign History',
  forceCampHistory: 'Force Campaign History',
  coCounselEmail: 'Co-Counsel Email',
  casesGoal: 'Cases Goal',
  caseCustomFields: 'Case Custom Fields',
  estimatedValue: 'Estimated Value',
  qualifiedSignedPrice: 'Qualified Signed Price',
  attorneyFeePercentage: 'Attorney Fee Percentage',
  attorneyFeeSplit: 'Attorney Fee Split',
  pricingNotes: 'Pricing Notes',
  dids: 'DIDs',
  disqualifications: 'Disqualifications',
  oppsCopyable: 'Opps Copyable',
  oppsCopyNewCampaignOptions: 'Opps Copy New Campaign Options',
  projectedConversionRate: 'Projected Conversion Rate',
  totalMarketingSpend: 'Total Marketing Spend',
  eversignTemplates: 'Eversign Templates',
  clientInfoEditing: 'Client Info Editing',
  showClientInfo: 'Show Client Info',
  questionnaireEditing: 'Questionnaire Editing',
  showQuestionnaire: 'Show Questionnaire',
  enableComments: 'Enable Comments',
  showComments: 'Show Comments',
  eversignBusinessId: 'Eversign Business ID',
  eversignRequestorName: 'Eversign Requester Name',
  eversignRequestorEmail: 'Eversign Requester Email',
  campaignNotes: 'Campaign Notes',
  separateESignWithAudit: 'Separate ESign With Audit',
  createdAt: 'Created', // date
  updatedAt: 'Updated' // date
  // eversignTemplateIds: 'Eversign Template ID',
  // questions: 'Questions', // obj
  // requiredQuestions: 'Required Questions'
}

const opportunityCoreContactFields = {
  firstName: { type: String, tableType: 'textField' },
  middleName: { type: String, tableType: 'textField' },
  lastName: { type: String, tableType: 'textField' },
  email: { type: String, tableType: 'textField' },
  phone: { type: String, tableType: 'textField' },
  phoneType: { type: String, tableType: 'textField' },
  phoneExtension: { type: String, tableType: 'textField' },
  addressStreet: { type: String, tableType: 'textField' },
  addressUnit: { type: String, tableType: 'textField' },
  addressCity: { type: String, tableType: 'textField' },
  addressState: { type: String, tableType: 'textField' },
  addressZIP: { type: String, tableType: 'textField' },
  addressCountry: { type: String, tableType: 'textField' },
  smartyStreetsVerified: { type: Boolean, tableType: 'boolean' },
  addressManuallyVerified: { type: Boolean, tableType: 'boolean' },
  timeZone: { type: String, tableType: 'textField' },
  autoTimeZone: { type: Boolean, tableType: 'boolean' },
  inmateId: { type: String, tableType: 'textField' },
  correctionalFacility: { type: String, tableType: 'textField' },
  dob: { type: Date, tableType: 'dateRange' },
  alternatePhone: { type: String, tableType: 'textField' },
  alternatePhoneExtension: { type: String, tableType: 'textField' },
  alternatePhoneType: { type: String, tableType: 'textField' },
  alternateName: { type: String, tableType: 'textField' },
  notes: { type: String, tableType: 'textField' },
  title: { type: String, tableType: 'textField' },
  suffix: { type: String, tableType: 'textField' },
  preferredName: { type: String, tableType: 'textField' },
  publisher: { type: String, tableType: 'textField' },
  dqReason: { type: String, tableType: 'textField' },
  secondaryEmail: { type: String, tableType: 'textField' },
  negotiatedRetainerApproval: { type: Boolean, tableType: 'boolean' },
  injuredPartyDifferent: { type: Boolean, tableType: 'boolean' },
  injuredPartyFirstName: { type: String, tableType: 'textField' },
  injuredPartyLastName: { type: String, tableType: 'textField' },
  injuredPartyDOD: { type: Date, tableType: 'dateRange' },
  injuredPartyDOB: { type: Date, tableType: 'dateRange' },
  injuredPartySuffix: { type: String, tableType: 'textField' },
  injuredPartyRelation: { type: String, tableType: 'textField' },
  injuredPartySSN: { type: String, tableType: 'textField' },
  injuredPartyGender: { type: String, tableType: 'textField' },
  callbackDateTime: { type: Date, tableType: 'dateRange' },
  deadline: { type: Date, tableType: 'dateRange' },
  gender: { type: String, tableType: 'textField' },
  originIp: { type: String, tableType: 'textField' },
  originURL: { type: String, tableType: 'textField' },
  userConsent: { type: Boolean, tableType: 'boolean' },
  userConsentType: { type: String, tableType: 'textField' },
  userConsentIds: { type: String, tableType: 'textField' }
}

const opportunityWebhookPayloadFields = {
  _id: { type: String, tableType: 'textField' },
  displayId: { type: String, tableType: 'textField' },
  externalId: { type: String, tableType: 'textField' },
  pin: { type: String, tableType: 'textField' },
  flag: { type: String, tableType: 'textField' },
  flagIds: { type: Array, tableType: 'textField' },
  notes: { type: String, tableType: 'textField' },
  dateSigned: { type: String, tableType: 'textField' },
  dateSentClient: { type: String, tableType: 'textField' },
  dateSentFirm: { type: String, tableType: 'textField' },
  createdAt: { type: String, tableType: 'textField' },
  updatedAt: { type: String, tableType: 'textField' }
}

const answerTypes = [
  'Boolean',
  'Date',
  'DateTime',
  'Number',
  'Text Field',
  'Secret',
  'Select Many',
  'Select One'
]

const restrictedOppStatuses = [
  'eSigWait',
  'qualifiedSent',
  'Retained_Hybrid',
  'Retained_In_House',
  'coCounselDecline',
  'Ready_For_Litigation',
  'firmDeclined',
  'pc_declined_after_signing',
  'pc_declined_after_switching_firms',
  'turn_down_never_signed',
  'approvedRetainer',
  'case_settled',
  'Addendum_Sent_for_Signature'
]

const restrictedCaseStatuses = [
  'Co-Counsel_Hold',
  'Case_Settled',
  'Claim_Filed',
  'Personal_Form_Holding',
  'Wrongful_Form_Holding'
]

const states = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'American Samoa', abbreviation: 'AS' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC' },
  { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Guam', abbreviation: 'GU' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Marshall Islands', abbreviation: 'MH' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Northern Mariana Islands', abbreviation: 'MP' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Palau', abbreviation: 'PW' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virgin Islands', abbreviation: 'VI' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' }
]

const defaultPhoneTypes = ['Home', 'Work', 'Cell']

const defaultTitles = ['Mr.', 'Mrs.', 'Ms.', 'Miss']

const defaultSuffixes = ['Sr.', 'Jr.', 'III', 'IV', 'V']

const caseSortFieldKeys = [ // keys of case that we might sort by
  'assignedUser',
  'caseStatus',
  'caseStatusUpdated',
  'filedCaseNumber',
  'claimId',
  'ballotId',
  'pocFiledDate',
  'pocSentForSigningDate',
  'pocSignedDate',
  'questionnaireCompletedDate',
  'questionnaireSentDate',
  'casePriority',
  'loanAmount',
  'loanProvider'
]

const timeZones = [ // Possible time zone returns from smarty streets along with time zone abbreviations for both standard and daylight times
  { name: 'Alaska', dst: 'America/Anchorage', std: 'America/Anchorage' },
  { name: 'Atlantic', dst: 'America/Halifax', std: 'AST' },
  { name: 'Eastern', dst: 'America/New_York', std: 'EST' },
  { name: 'Central', dst: 'America/Chicago', std: 'CST' },
  { name: 'Hawaii', dst: 'Pacific/Honolulu', std: 'HST' },
  { name: 'Mountain', dst: 'America/Denver', std: 'MST' },
  { name: 'Pacific', dst: 'America/Los_Angeles', std: 'PST' },
  { name: 'Samoa', dst: 'Pacific/Samoa', std: 'SST' }
]

/**
 * Exports everything that could be needed to create shortcuts to menu entries.
 * - REQUIRED [page] is set primarily for checking permissions.  If page permission props is different from page file, use route to provide the page filename.
 * - (optional) [read] overrides page to provide a specific string to check against for READ permissions.
 * - (optional) [route] overrides page for the url.    e.g. Home pages uses '/' for url instead of '/home'
 * - (optional) [extraTesting] can provide a boolean result of any number of extra pure expressions.    e.g. !!( process.env.NODE_ENV !== 'test' )
 * - (optional) [emailMustInclude] can provide a string that when defined must be in the users email.      e.g. '@caseopp.com' )
 */
const navigationMenu = [
  {
    label: 'Home',
    page: 'home',
    route: '',
    icon: <HomeIcon />,
    loadingText: 'home-page-load',
    allowHomePage: true
  },
  {
    label: 'Intake Dashboard',
    page: 'intakeDashboard',
    icon: <InputIcon />,
    loadingText: 'intake-home-page-load',
    allowHomePage: true
  },
  {
    label: 'Case Manager Dashboard',
    page: 'caseManagerDashboard',
    icon: <SupervisorAccountIcon />,
    loadingText: 'case-manager-home-page-load',
    allowHomePage: true
  },
  {
    label: 'Callback Dashboard',
    page: 'callbackDashboard',
    icon: <PhoneCallback />,
    loadingText: 'callback-dashboard-page-load',
    allowHomePage: true
  },
  {
    label: 'Calendars',
    page: 'calendars',
    icon: <CalendarTodayIcon />,
    loadingText: 'calendars-page-load',
    allowHomePage: false
  },
  {
    label: 'Supervisor Dashboard',
    page: 'supervisorDashboard',
    icon: <HailIcon />,
    loadingText: 'supervisor-dashboard-page-load',
    allowHomePage: true
  },
  {
    label: 'Opportunities',
    page: 'opportunities',
    icon: <ContactsOutlinedIcon />,
    loadingText: 'opportunities-page-load',
    allowHomePage: true
  },
  {
    label: 'New Opportunities Grid',
    page: 'newOppsGrid',
    icon: <AssignmentIndIcon />,
    loadingText: 'new-opps-grid-page-load',
    allowHomePage: true
  },
  {
    label: 'Cases',
    page: 'cases',
    icon: <GavelIcon />,
    loadingText: 'cases-page-load',
    allowHomePage: true
  },
  {
    label: 'Campaigns',
    page: 'campaigns',
    icon: <QuestionAnswerOutlinedIcon />,
    loadingText: 'campaigns-page-load',
    allowHomePage: true
  },
  {
    label: 'Phone',
    page: 'phone',
    icon: <PhoneIcon />,
    loadingText: 'phone-page-load',
    allowHomePage: false
  },
  {
    label: 'Import / Update Tool',
    page: 'importUpdate',
    icon: <InputIcon />,
    loadingText: 'import-page-load',
    allowHomePage: true
  },
  {
    label: 'Settings',
    page: 'settings',
    icon: <TuneIcon />,
    loadingText: 'settings-page-load',
    allowHomePage: true
  },
  {
    label: 'Campaign Stats',
    page: 'casesDashboard',
    route: 'campaignStats',
    icon: <PollIcon />,
    loadingText: 'campaign-stats-page-load',
    allowHomePage: false
  },
  {
    label: 'User Management',
    page: 'users',
    icon: <VpnKeyIcon />,
    loadingText: 'users-page-load',
    allowHomePage: false
  },
  {
    label: 'Notification Management',
    page: 'manageNotifications',
    icon: <NotificationsIcon />,
    loadingText: 'notifications-page-load',
    allowHomePage: false
  },
  {
    label: 'Downloads',
    page: 'downloads',
    icon: <CloudIcon />,
    loadingText: 'downloads-page-load',
    allowHomePage: false
  },
  {
    label: 'Reports',
    page: 'reportEngine',
    icon: <LibraryBooksIcon />,
    loadingText: 'reports-page-load',
    allowHomePage: false
  },
  {
    label: 'Release Notes',
    page: 'releaseNotes',
    icon: <NotesIcon />,
    loadingText: 'release-notes-load',
    allowHomePage: true
  },
  {
    label: 'Voicemails',
    page: 'voicemails',
    icon: <VoicemailIcon />,
    allowHomePage: false
  },
  {
    label: 'Communication Dashboard',
    page: 'communicationDashboard',
    icon: <EmailIcon />,
    loadingText: 'communication-dashboard-load',
    allowHomePage: true
  },
  {
    label: 'Support',
    page: 'support',
    read: 'supporticle',
    icon: <HelpIcon />,
    loadingText: 'support-page-load',
    allowHomePage: false
  },
  {
    label: 'Partner Portals',
    page: 'partners',
    read: 'partnerPortal',
    icon: <GroupWorkIcon />,
    loadingText: 'partner-portals-page-load',
    allowHomePage: true
  },
  {
    label: 'Report Bug',
    page: 'bugreport',
    icon: <BugReportIcon />,
    loadingText: 'bug-page-load',
    allowHomePage: false
  },
  {
    label: 'Logout',
    page: 'logout',
    route: 'api/auth/logout',
    icon: <PowerSettingsNewIcon />,
    allowHomePage: false
  }
]

const genders = ['', 'Male', 'Female', 'Other']

const ignoreDST = [ // States that ignore daylight savings time. Used for determining opportunity time
  'Arizona',
  'AZ',
  'Hawaii',
  'HI',
  'Indiana',
  'IN'
]
const neverNeedToBeMergeFields = [
  '__v',
  'answers',
  'assignedUser',
  'assureSignDocuments',
  'campaignId',
  'caseComments',
  'caseCommentExcerpts',
  'caseStatus',
  'caseStatusUpdated',
  'caseStatusLabel',
  'clientCommentExcerpt',
  'clientComments',
  'clientPortalTokens',
  'commentExcerpt',
  'comments',
  'doNotMail',
  'Do Not Call Phone',
  'Do Not Text Phone',
  'Do Not Call Alternate Phone',
  'Do Not Text Alternate Phone',
  'Do Not Email Primary',
  'Do Not Email Secondary',
  'eversignDocuments',
  'errors',
  'flag',
  'flagIds',
  'forceHistory',
  'forceCampHistory',
  'head',
  'history',
  'id',
  '$init',
  'initCampHistory',
  'initHistory',
  'intakeQA',
  'isCase',
  'isNew',
  'lockedUser',
  'questionsAndAnswers',
  'reviewUser',
  'paralegalUser',
  'scrambled',
  'skipEventActions',
  'tokens'
]

const emailSignatureMergeFields = [
  { displayName: 'First Name', value: 'userFirstName' },
  { displayName: 'Last Name', value: 'userLastName' },
  { displayName: 'Job Title', value: 'userJobTitle' },
  { displayName: 'Reply Email Address', value: 'replyEmailAddress' },
  { displayName: 'All Signature Fields', value: 'emailSignature' }
]

const oppTabLabels = {
  clientInfo: 'Client Info',
  questions: 'Questions',
  history: 'History',
  calendar: 'Calendar',
  documents: 'Documents',
  comments: 'Comments',
  communications: 'Communications',
  payments: 'Payments',
  caseDetails: 'Case Details'
}
const fieldsWithTimestampValues = [
  'createdAt',
  'dateSentClient',
  'dateSentFirm',
  'dateSigned',
  'dob',
  'pocFiledDate',
  'pocSentForSigningDate',
  'pocSignedDate',
  'questionnaireSentDate',
  'timestamp',
  'updated',
  'updatedAt',
  'injuredPartyDOB',
  'injuredPartyDOD',
  'deadline'
]

export const dateRangeFields = [
  'timestamp',
  'updated',
  'dateSentClient',
  'dateSentFirm',
  'dateSigned',
  'pocSentForSigningDate',
  'pocSignedDate',
  'pocFiledDate',
  'clientPortalLastAccessed'
]

const campaignFields = [
  { field: 'firms', text: 'Firms' },
  { field: 'priority', text: 'Priority' },
  { field: 'groups', text: 'Groups' },
  { field: 'dids', text: 'DIDs' },
  { field: 'smsTemplates', text: 'SMS templates' },
  { field: 'coCounselPortal', text: 'Co-Counsel Portal' },
  { field: 'questions', text: 'Questions' },
  { field: 'caseCustomFields', text: 'Case Custom Fields' },
  { field: 'assignableUserList', text: 'Assignable User List' },
  { field: 'caseGoal', text: 'Case Goal' },
  { field: 'campaignStatus', text: 'Campaign Status' },
  { field: 'coCounselEmail', text: 'Co-Counsel Email' },
  { field: 'disqualifications', text: 'Disqualifications' },
  { field: 'conversionRate', text: 'Projected Conversion Rate' },
  { field: 'marketingBudget', text: 'Marketing Budget' },
  { field: 'copyOpps', text: 'Copy Opps Permission' },
  { field: 'copyOppsCampaigns', text: 'Allowed Campaigns To Copy To' },
  { field: 'flags', text: 'Flags' },
  { field: 'statuses', text: 'Statuses' },
  { field: 'eventActions', text: 'Event Actions' },
  { field: 'assureSignDocs', text: 'AssureSign Documents' },
  { field: 'eversignDocs', text: 'Eversign Documents' },
  { field: 'docTemplates', text: 'Document Templates' },
  { field: 'attorneyFee', text: 'Attorney Fees and Percentages' },
  { field: 'emails', text: 'Email Templates' }
]

const userActionsDictionary = [
  { value: 'sentEmail', displayName: 'Sent Email' },
  { value: 'sentText', displayName: 'Sent Text' },
  { value: 'openedOpportunity', displayName: 'Opened Opportunity' },
  { value: 'createdOpportunity', displayName: 'Created Opportunity' },
  { value: 'createdComment', displayName: 'Created Comment' },
  { value: 'phoneCallMade', displayName: 'Made Phone Call' },
  { value: 'phoneCallReceived', displayName: 'Received Phone Call' },
  { value: 'documentSent', displayName: 'Sent Document' },
  { value: 'opportunitySearch', displayName: 'Searched Opportunities' },
  { value: 'opportunityUpdate', displayName: 'Opportunity Updated' }
]

const smsHumanReadable = {
  type: 'Message Type',
  opportunityDisplayId: 'Opportunity ID',
  time: 'Time Sent/Received',
  subject: 'Subject',
  text: 'Message',
  from: 'From',
  lastRespondent: 'Last Responder',
  contactNumber: 'Contact Number'
}

export const opportunityOriginations = [
  { id: 'Phone', name: 'Phone' },
  { id: 'API', name: 'API' },
  { id: 'Import', name: 'Import Tool' },
  { id: 'Email', name: 'Email' },
  { id: 'liveChat', name: 'Live Chat' },
  { id: 'copied', name: 'Copied' }
]

const phoneAreaCodes = [
  { 201: 'EST' },
  { 202: 'EST' },
  { 203: 'EST' },
  { 205: 'CST' },
  { 206: 'PST' },
  { 207: 'EST' },
  { 208: 'MST' },
  { 209: 'PST' },
  { 210: 'CST' },
  { 212: 'EST' },
  { 213: 'PST' },
  { 214: 'CST' },
  { 215: 'EST' },
  { 216: 'EST' },
  { 217: 'CST' },
  { 218: 'CST' },
  { 219: 'CST' },
  { 220: 'EST' },
  { 223: 'EST' },
  { 224: 'CST' },
  { 225: 'CST' },
  { 228: 'CST' },
  { 229: 'EST' },
  { 231: 'EST' },
  { 234: 'EST' },
  { 239: 'EST' },
  { 240: 'EST' },
  { 248: 'EST' },
  { 251: 'CST' },
  { 252: 'EST' },
  { 253: 'PST' },
  { 254: 'CST' },
  { 256: 'CST' },
  { 260: 'EST' },
  { 262: 'CST' },
  { 267: 'EST' },
  { 269: 'EST' },
  { 270: 'CST' },
  { 272: 'EST' },
  { 276: 'EST' },
  { 279: 'PST' },
  { 281: 'CST' },
  { 301: 'EST' },
  { 302: 'EST' },
  { 303: 'MST' },
  { 304: 'EST' },
  { 305: 'EST' },
  { 307: 'MST' },
  { 308: 'CST' },
  { 309: 'CST' },
  { 310: 'PST' },
  { 312: 'CST' },
  { 313: 'EST' },
  { 314: 'CST' },
  { 315: 'EST' },
  { 316: 'CST' },
  { 317: 'EST' },
  { 318: 'CST' },
  { 319: 'CST' },
  { 320: 'CST' },
  { 321: 'EST' },
  { 323: 'PST' },
  { 325: 'CST' },
  { 326: 'EST' },
  { 330: 'EST' },
  { 331: 'CST' },
  { 332: 'EST' },
  { 334: 'CST' },
  { 336: 'EST' },
  { 337: 'CST' },
  { 339: 'EST' },
  { 341: 'PST' },
  { 346: 'CST' },
  { 347: 'EST' },
  { 351: 'EST' },
  { 352: 'EST' },
  { 360: 'PST' },
  { 361: 'CST' },
  { 364: 'CST' },
  { 380: 'EST' },
  { 385: 'MST' },
  { 386: 'EST' },
  { 401: 'EST' },
  { 402: 'CST' },
  { 404: 'EST' },
  { 405: 'CST' },
  { 406: 'MST' },
  { 407: 'EST' },
  { 408: 'PST' },
  { 409: 'CST' },
  { 410: 'EST' },
  { 412: 'EST' },
  { 413: 'EST' },
  { 414: 'CST' },
  { 415: 'PST' },
  { 417: 'CST' },
  { 419: 'EST' },
  { 423: 'EST' },
  { 424: 'PST' },
  { 425: 'PST' },
  { 430: 'CST' },
  { 432: 'CST' },
  { 434: 'EST' },
  { 435: 'MST' },
  { 440: 'EST' },
  { 442: 'PST' },
  { 443: 'EST' },
  { 445: 'EST' },
  { 447: 'CST' },
  { 448: 'CST' },
  { 456: '' },
  { 463: 'EST' },
  { 464: 'CST' },
  { 469: 'CST' },
  { 470: 'EST' },
  { 475: 'EST' },
  { 478: 'EST' },
  { 479: 'CST' },
  { 480: 'MST' },
  { 484: 'EST' },
  { 500: '' },
  { 502: 'EST' },
  { 503: 'PST' },
  { 504: 'CST' },
  { 505: 'MST' },
  { 507: 'CST' },
  { 508: 'EST' },
  { 509: 'PST' },
  { 510: 'PST' },
  { 512: 'CST' },
  { 513: 'EST' },
  { 515: 'CST' },
  { 516: 'EST' },
  { 517: 'EST' },
  { 518: 'EST' },
  { 520: 'MST' },
  { 521: '' },
  { 531: 'CST' },
  { 533: '' },
  { 539: 'CST' },
  { 540: 'EST' },
  { 541: 'PST' },
  { 544: '' },
  { 559: 'PST' },
  { 561: 'EST' },
  { 562: 'PST' },
  { 563: 'CST' },
  { 564: 'PST' },
  { 566: '' },
  { 570: 'EST' },
  { 571: 'EST' },
  { 572: 'CST' },
  { 573: 'CST' },
  { 574: 'EST' },
  { 575: 'MST' },
  { 580: 'CST' },
  { 582: 'EST' },
  { 585: 'EST' },
  { 586: 'EST' },
  { 588: '' },
  { 602: 'MST' },
  { 603: 'EST' },
  { 605: 'CST' },
  { 606: 'EST' },
  { 607: 'EST' },
  { 608: 'CST' },
  { 609: 'EST' },
  { 610: 'EST' },
  { 611: '' },
  { 614: 'EST' },
  { 615: 'CST' },
  { 616: 'EST' },
  { 617: 'EST' },
  { 618: 'CST' },
  { 619: 'PST' },
  { 620: 'CST' },
  { 623: 'MST' },
  { 626: 'PST' },
  { 628: 'PST' },
  { 629: 'CST' },
  { 630: 'CST' },
  { 631: 'EST' },
  { 636: 'CST' },
  { 640: 'EST' },
  { 641: 'CST' },
  { 646: 'EST' },
  { 650: 'PST' },
  { 651: 'CST' },
  { 656: 'EST' },
  { 657: 'PST' },
  { 659: 'CST' },
  { 660: 'CST' },
  { 661: 'PST' },
  { 662: 'CST' },
  { 667: 'EST' },
  { 669: 'PST' },
  { 678: 'EST' },
  { 680: 'EST' },
  { 681: 'EST' },
  { 682: 'CST' },
  { 689: 'EST' },
  { 700: '' },
  { 702: 'PST' },
  { 703: 'EST' },
  { 704: 'EST' },
  { 706: 'EST' },
  { 707: 'PST' },
  { 708: 'CST' },
  { 710: '' },
  { 713: 'CST' },
  { 714: 'PST' },
  { 715: 'CST' },
  { 716: 'EST' },
  { 717: 'EST' },
  { 718: 'EST' },
  { 719: 'MST' },
  { 720: 'MST' },
  { 724: 'EST' },
  { 725: 'PST' },
  { 726: 'CST' },
  { 727: 'EST' },
  { 731: 'CST' },
  { 732: 'EST' },
  { 734: 'EST' },
  { 737: 'CST' },
  { 740: 'EST' },
  { 743: 'EST' },
  { 747: 'PST' },
  { 753: 'EST' },
  { 754: 'EST' },
  { 757: 'EST' },
  { 760: 'PST' },
  { 762: 'EST' },
  { 763: 'CST' },
  { 765: 'EST' },
  { 769: 'CST' },
  { 770: 'EST' },
  { 771: 'EST' },
  { 772: 'EST' },
  { 773: 'CST' },
  { 774: 'EST' },
  { 775: 'PST' },
  { 779: 'CST' },
  { 781: 'EST' },
  { 785: 'CST' },
  { 786: 'EST' },
  { 800: '' },
  { 802: 'EST' },
  { 803: 'EST' },
  { 804: 'EST' },
  { 805: 'PST' },
  { 806: 'CST' },
  { 808: 'HST' },
  { 809: 'AST' },
  { 810: 'EST' },
  { 812: 'EST' },
  { 813: 'EST' },
  { 814: 'EST' },
  { 815: 'CST' },
  { 816: 'CST' },
  { 817: 'CST' },
  { 818: 'PST' },
  { 820: 'PST' },
  { 828: 'EST' },
  { 830: 'CST' },
  { 831: 'PST' },
  { 832: 'CST' },
  { 833: '' },
  { 839: 'EST' },
  { 840: 'PST' },
  { 843: 'EST' },
  { 844: '' },
  { 847: 'CST' },
  { 848: 'EST' },
  { 850: 'CST' },
  { 854: 'EST' },
  { 855: '' },
  { 857: 'EST' },
  { 858: 'PST' },
  { 859: 'EST' },
  { 860: 'EST' },
  { 862: 'EST' },
  { 863: 'EST' },
  { 864: 'EST' },
  { 865: 'EST' },
  { 866: '' },
  { 872: 'CST' },
  { 877: '' },
  { 880: '' },
  { 903: 'CST' },
  { 904: 'EST' },
  { 906: 'EST' },
  { 907: 'AKST' },
  { 908: 'EST' },
  { 909: 'PST' },
  { 910: 'EST' },
  { 912: 'EST' },
  { 913: 'CST' },
  { 914: 'EST' },
  { 915: 'MST' },
  { 916: 'PST' },
  { 917: 'EST' },
  { 918: 'CST' },
  { 919: 'EST' },
  { 920: 'CST' },
  { 925: 'PST' },
  { 928: 'MST' },
  { 929: 'EST' },
  { 930: 'EST' },
  { 931: 'CST' },
  { 934: 'EST' },
  { 936: 'CST' },
  { 937: 'EST' },
  { 938: 'CST' },
  { 940: 'CST' },
  { 941: 'EST' },
  { 943: 'EST' },
  { 945: 'CST' },
  { 947: 'EST' },
  { 949: 'PST' },
  { 951: 'PST' },
  { 952: 'CST' },
  { 954: 'EST' },
  { 956: 'CST' },
  { 959: 'EST' },
  { 970: 'MST' },
  { 971: 'PST' },
  { 972: 'CST' },
  { 973: 'EST' },
  { 978: 'EST' },
  { 979: 'CST' },
  { 980: 'EST' },
  { 984: 'EST' },
  { 985: 'CST' },
  { 986: 'MST' },
  { 989: 'EST' }
]

const fieldIndexOrder = [
  'head',
  '_id',
  'campaignId',
  'status',
  'displayId',
  'updated',
  'timestamp',
  'comments.comment',
  'comments.user',
  'firstName',
  'lastName',
  'email',
  'secondaryEmail',
  'phone',
  'alternatePhone',
  'managerUser',
  'reviewUser',
  'paralegalUser',
  'injuredPartyLastName',
  'injuredPartSSN',
  'injuredPartyGender',
  'isCase',
  'emails.handled'
]

const allowedGridStringOperators = [
  // 'contains', disabling as this causes very slow performance
  'equals',
  // 'startsWith', disabling as this causes very slow performance
  // 'endsWith', disabling as this causes very slow performance
  'isEmpty',
  'isNotEmpty',
  'isAnyOf'
]

const allowedGridDateOperators = [
  // 'is',
  // 'isNot',
  'before',
  'after',
  'onOrBefore',
  'onOrAfter',
  'isEmpty',
  'isNotEmpty'
]

const conditionalOperators = [
  { id: 'contains', name: 'Contains', types: ['Select Many'] },
  { id: 'doesNotContain', name: 'Does Not Contain', types: ['Select Many'] },
  { id: 'isAnyOf', name: 'Is Any Of', types: ['Select Many', 'Select One'] },
  { id: 'isNotAnyOf', name: 'Is Not Any Of', types: ['Select Many', 'Select One'] },
  { id: 'is', name: 'Is', types: ['Boolean', 'Number', 'Text Field', 'Select Many', 'Select One'] },
  { id: 'isNot', name: 'Is Not', types: ['Boolean', 'Number', 'Text Field', 'Select Many', 'Select One'] },
  { id: 'isEmpty', name: 'Is Empty', types: ['Text Field', 'Number', 'Select Many', 'Select One'] },
  { id: 'isNotEmpty', name: 'Is Not Empty', types: ['Text Field', 'Number', 'Select Many', 'Select One'] },
  { id: 'greaterThan', name: 'Greater Than', types: ['Number'] },
  { id: 'greaterThanOrEqualTo', name: 'Greater Than Or Equal To', types: ['Number'] },
  { id: 'lessThan', name: 'Less Than', types: ['Number'] },
  { id: 'lessThanOrEqualTo', name: 'Less Than Or Equal To', types: ['Number'] },
  { id: 'isOlderThan', name: 'Is Older Than', types: ['Date'] },
  { id: 'isNewerThan', name: 'Is Newer Than', types: ['Date'] },
  { id: 'isBefore', name: 'Is Before', types: ['Date'] },
  { id: 'isAfter', name: 'Is After', types: ['Date'] }
]

const conditionalOperatorValueTypes = [
  {
    type: 'Boolean',
    fieldType: 'select',
    optionTypes: 'static',
    options: [
      { value: 'true', label: 'True' },
      { value: 'false', label: 'False' }
    ]
  },
  {
    type: 'Number',
    fieldType: 'number'
  },
  {
    type: 'Text Field',
    fieldType: 'text'
  },
  {
    type: 'Select Many',
    fieldType: 'select',
    optionTypes: 'dynamic'
  },
  {
    type: 'Select One',
    fieldType: 'select',
    optionTypes: 'dynamic'
  },
  {
    type: 'Date',
    fieldType: 'date'
  }
]

export const validationRegex = {
  email: {
    regex: /^[A-Za-z0-9.#_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g,
    message: 'Please Enter a valid Email Address with no spaces at the beginning or end.'
  },
  phone: {
    regex: /^\d{3}-\d{3}-\d{4}$/g,
    message: 'Phone numbers should be in ###-###-#### format with no spaces at the beginning or end.'
  },
  name: {
    regex: /^[A-Za-zÀ-ÖØ-öø-ÿ-]+((['. -][A-Za-zÀ-ÖØ-öø-ÿ- ])?[A-Za-zÀ-ÖØ-öø-ÿ-]*)*$/g,
    message: 'Names should only have letters, hyphens, or spaces between words; No spaces at the beginning or end.'
  },
  ssn: {
    regex: /^\d{3}-\d{2}-\d{4}$/g,
    message: 'SSN should be in ###-##-#### format with no spaces at the beginning or end.'
  }
}

export const roleNamesInDB = {
  Admin: 'admin',
  'Admin Portal Admin': 'adminPortalAdmin',
  Attorney: 'attorney',
  'Case Managers': 'caseManager',
  Client: 'client',
  CoCounsel: 'coCounsel',
  Firm: 'firm',
  'Firm Admin': 'firmAdmin',
  'Intake Agent': 'intakeAgent',
  'Legal Assistant': 'legalAssistant',
  'Litigation Support Specialist': 'litigationSupportUser',
  Paralegal: 'paralegal',
  Partner: 'partner',
  'Remote Agent': 'remoteIntake',
  'Review Specialist': 'reviewSpecialist',
  'Super Admin': 'superAdmin',
  Supervisor: 'supervisor',
  Trainee: 'trainee'
}

module.exports = {
  answerTypes,
  campaignFields,
  caseSortFieldKeys,
  defaultPhoneTypes,
  defaultSuffixes,
  defaultTitles,
  genders,
  fieldsWithTimestampValues,
  ignoreDST,
  neverNeedToBeMergeFields,
  opportunityCoreContactFields,
  opportunityWebhookPayloadFields,
  oppTabLabels,
  emailSignatureMergeFields,
  restrictedOppStatuses,
  restrictedCaseStatuses,
  states,
  timeZones,
  userActionsDictionary,
  userFacingClientInfoFieldNamesDict,
  userFacingCaseFieldNamesDict,
  userFacingOppFieldNamesDict,
  userFacingOppFieldNamesImportDict,
  userFacingCampaignFieldNamesDict,
  dateRangeFields,
  smsHumanReadable,
  navigationMenu,
  opportunityOriginations,
  phoneAreaCodes,
  fieldIndexOrder,
  allowedGridStringOperators,
  allowedGridDateOperators,
  conditionalOperators,
  conditionalOperatorValueTypes,
  validationRegex,
  roleNamesInDB
}
